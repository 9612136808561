import React, { useEffect, useState } from 'react'
import './status-bar-with-icons.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward, faFileLines, faListCheck, faUpload } from '@fortawesome/free-solid-svg-icons'

const StatusBarWithIcons = ({ progressNumber, mcData }) => {

    const [ progNum, setProgNum ] = useState(0);

    useEffect(() => {
        if (!progressNumber || !mcData) return;
        setProgNum(progressNumber.progNum)

    }, [ progressNumber, mcData ])

    return (
        <div className='status-bar-with-icons'>                                
            <div className='materials icon-div' title='View Materials'>
                <FontAwesomeIcon 
                    icon={faFileLines} 
                    size="xl" 
                    className={`status-icon ${
                        (progNum >= 1) 
                        ? 'done' 
                        : ''
                    }`} 
                />
            </div>
            <div className='precheck icon-div' title='Upload Precheck'>
                <FontAwesomeIcon 
                    icon={faUpload} 
                    size="xl" 
                    className={`status-icon ${
                        (progNum === 2) 
                        ? 'pending' 
                        : (progNum === 3) 
                            ? 'declined' 
                            : (progNum >= 4) 
                                ? 'done' 
                                : ''
                    }`}
                />
            </div>
            <div className='artifacts icon-div' title='Portfolio Artifacts'>
                <FontAwesomeIcon 
                    icon={faListCheck} 
                    size="xl" 
                    className={`status-icon ${
                        (progNum === 5 || progNum === 6) 
                        ? 'in-progress' 
                        : (progNum === 7) 
                            ? 'pending' 
                            : (progNum === 8) 
                                ? 'declined' 
                                : (progNum >= 9)
                                    ? 'done' 
                                    : ''
                    }`}
                />
            </div>
            {/* <div className='submit' title='Artifacts Submitted'>
                <FontAwesomeIcon 
                    icon={faFlagCheckered} 
                    size="2x" 
                />
            </div> */}
            <div className='certificate icon-div' title='Approved!'>
                <FontAwesomeIcon 
                    icon={faAward} 
                    size="xl"
                    className={`complete ${
                        progNum === 9 
                        ? 'in-progress' 
                        : progNum >= 9 
                            ? 'done' 
                            : ''
                    }`} 
                />
            </div>
        </div>
    )
}

export default StatusBarWithIcons
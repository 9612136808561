import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/layout-auth/layout.comp'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContainer from '@/components/HOME/dashboard/dashboard-container.component'
import { getEarnedBadgesFromDb, getUniqueDocFromDb, getUserMcsFromDb } from '@/utils/getDataFromDb'
import { setEvaluatingList, setMyMcList, setMyProgress } from '@/state/slices/contentSlice'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import MyMicrocredentials from '@/components/HOME/my-microcredentials/my-microcredentials.component'
import EvaluatingMcList from '@/components/HOME/evaluating/evaluating-mc-list.component'
import MyBadgesContainer from '@/components/HOME/my-badges/my-badges-container.component'
import { UseCloudFunction } from '@/utils/firebase.utils'
import { format } from 'date-fns'


const HomePage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ evaluatingList, setEvaluatingList ] = useState(null);
    const [ nfeRegs, setNfeRegs] = useState(null)
    const [ userProgressNumbers, setUserProgressNumbers ] = useState({});
    const [ evalProgressNumbers, setEvalProgressNumbers ] = useState({});
    const [ earnedBadges, setEarnedBadges ] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const mcRef = useRef();
    const evalRef = useRef();
    const nfeRegsRef = useRef();
    const userProgressRef = useRef();
    const evalProgressRef = useRef();
    const badgesRef = useRef();
    let mcListener;
    let progNumListener;
    let badgesListener;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!userProfile) {
                navigate('/logout');
            }
        }, 3000)

        return () => {
            if (mcListener) mcListener();
            if (progNumListener) progNumListener();
            if (badgesListener) badgesListener();
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        if (!userProfile) {return;}
        getExistingMcs();
        getEarnedBadges();
        if (
            (
                location.pathname === '/home/' ||
                location.pathname === '/home' 
            ) && userProfile.permiss_eval
        ) {
            navigate('evaluating');
        } else if (
            location.pathname === '/home/' ||
            location.pathname === '/home'
        ) {
            navigate('mymicrocredentials');
        }
    }, [userProfile])

    function getExistingMcs() {
        getUserMcsFromDb({'cst_key': userProfile.cst_key, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            mcRef.current = {};
            let evaluator = false;
            for (let mc of Object.values(data)) {
                const mcData = mc.mcData;
                // const progress = mc.progress;
                
                if (mcData.roles.includes('part')) {
                    mcRef.current = {...mcRef.current, ...{[mcData.mc_key]: mcData}};
                    getUserProgressNumber(mcData.mc_key, 'part');
                }
                if (mcData.roles.includes('eval')) {
                    console.log(mcData);
                    evalRef.current = {...evalRef.current, ...{[mcData.mc_key]: mcData}};
                    getUserProgressNumber(mcData.mc_key, 'eval');
                    evaluator = true
                }
                // if (progress) {
                //     progressRef.current = {...progressRef.current, ...{[`${progress.mc_key}_${progress.cst_key}`]: progress}};
                //     setMyProgress(progressRef.current);
                // }
            }
            dispatch(setMyMcList(mcRef.current));
            setEvaluatingList(evalRef.current);
            if (evaluator) {
                getNfeRegistrants();
            }
        }
        function handleListener(unsub) {
            mcListener = unsub;
        }
    }

    function getUserProgressNumber(mc_key, partEval) {
        // console.log(mc_key)
        getUniqueDocFromDb({'path': `progressNumbers/${mc_key}`, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!data) return;
            if (partEval === 'part') {
                userProgressRef.current = {...userProgressRef.current, ...{[mc_key]: data[userProfile.cst_key]}}
                setUserProgressNumbers(userProgressRef.current);
            } else {
                evalProgressRef.current = {...evalProgressRef.current, ...{[mc_key]: data}}
                setEvalProgressNumbers(evalProgressRef.current);
            }   
        }
        function handleListener(unsub) {
            progNumListener = unsub;
        }
    }

    async function getNfeRegistrants() {
        let mcIdRef = {};
        await getUniqueDocFromDb({
            'path': 'utils/mcIdRef',
            'once': true,
            'callback': callback
        })
        function callback(data) {
            mcIdRef = {...data};
        }
        const res = await UseCloudFunction(
            "getMCRegistrantsFromNFE",
            {
                "cst_key": userProfile.cst_key,
            }
        )
        if (!res.data) return;
        // console.log(res.data);
        for (let mc of Object.values(mcIdRef)) {
            let filteredRegs = res.data.filter(r => r.evt_code === mc.nfe_code);
            let obj = {
                "count": filteredRegs.length,
                "regs": {}
            }
            for (let u of filteredRegs) {
                obj.regs[u.ind_cst_key] = {
                    "fName": u.ind_first_name,
                    "lName": u.ind_last_name,
                    "cst_key": u.ind_cst_key,
                    "regDate": new Date(u['Registrant.reg_add_date']).getTime(),
                    "email": u.cst_eml_address_dn
                }
            } 
            nfeRegsRef.current = {...nfeRegsRef.current, ...{[mc.mc_key]: obj}}
        }
        setNfeRegs(nfeRegsRef.current);
        // console.log(nfeRegsRef.current);
    }

    function getEarnedBadges() {
        getEarnedBadgesFromDb({"cst_key": userProfile.cst_key, "callback": callback, "handleListener": handleListener})
        function callback(data) {
            badgesRef.current = {...badgesRef.current, ...{[data.mc_key]: data}}
            setEarnedBadges(badgesRef.current);
        }
        function handleListener(unsub) {
            badgesListener = unsub;
        } 
    }

    return (
        <div>
            <Layout>
                <Routes>
                    <Route path='' element={
                        <DashboardContainer 
                            evaluatingList={evaluatingList}
                            userProgressNumbers={userProgressNumbers}
                        />
                    }></Route>
                    <Route path='mymicrocredentials' element={
                        <MyMicrocredentials  
                            evaluatingList={evaluatingList}
                            userProgressNumbers={userProgressNumbers}
                        />
                    }></Route>
                    <Route path='evaluating/*' element={
                        <EvaluatingMcList
                            evaluatingList={evaluatingList}
                            evalProgressNumbers={evalProgressNumbers}
                            nfeRegs={nfeRegs}
                        />
                    }></Route>
                    <Route path='mybadges/*' element={
                        <MyBadgesContainer 
                            earnedBadges={earnedBadges} 
                        />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </Layout>
        </div>
    )
}

export default HomePage
import React from 'react';
import './cohort-list.styles.scss';

const CohortList = ({
    cohorts
}) => {
    return (    
        <div className='cohort-list'>
            <table className='g-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Participants</th>
                        <th>MCs</th>
                        <th></th>
                    </tr>
                </thead>
                

            </table>
        </div>
    )
}

export default CohortList
import React from 'react';
import './cohorts-container.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CohortList from './cohort-list.component';
import CohortCreateForm from './cohort-create-form.component';

const CohortsContainer = ({
    users,
    mcList,
    cohorts
}) => {

    const navigate = useNavigate();

    return (
        <div className='cohorts-container'>
            <Routes>
                <Route path='' element={
                    <div className='cohorts-landing-content'>
                        <div className='section-title with-icon'>
                            Manage Cohorts
                            <button
                                className='g-button'
                                title='Create New Cohort'
                                onClick={() => navigate('create')}>   
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <CohortList
                            cohorts={cohorts} />
                        
                    </div>
                }></Route>
                <Route path='create' element={
                    <div className='g-card'>
                        <CohortCreateForm
                            users={users}
                            mcList={mcList} />
                    </div>
                }></Route>
            </Routes>
            
        </div>
    )
}

export default CohortsContainer
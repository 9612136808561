import React, { useEffect, useRef, useState } from 'react'
import './view-eval-data.styles.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '@/components/back-button/back-button.component';
import StatusBarWithIcons from '../my-microcredentials/status-bar-with-icons.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { format } from 'date-fns';
import { setCurrentMc } from '@/state/slices/contentSlice';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { getUniqueDocFromDb } from '@/utils/getDataFromDb';
import { UseCloudFunction } from '@/utils/firebase.utils';

const ViewEvalData = ({ 
    nfeRegs,
    evalProgressNumbers,
    evaluatingList  }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ currentMcDisplay, setCurrentMcDisplay ] = useState(null);
    const [ evalRegs, setEvalRegs ] = useState(null);
    const [ users, setUsers ] = useState({});
    const [ inProgress, setInProgress ] = useState([]);
    const [ complete, setComplete ] = useState([]);
    const [ notStarted, setNotStarted ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const { mc_key } = useParams();
    const usersRef = useRef();
    const inProgressRef = useRef();
    const completeRef = useRef();
    const notStartedRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000)

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, []);

    useEffect(() => {
        if (!nfeRegs || !mc_key) return;
        setEvalRegs(nfeRegs[mc_key])
        console.log(nfeRegs[mc_key]);
    }, [nfeRegs, mc_key]);

    useEffect(() => {
        if (!mc_key || !evaluatingList || !evalRegs || !userProfile) {return;}
        console.log(evalRegs);
        const mc = evaluatingList[mc_key];
        setCurrentMcDisplay(mc);

        // loop through the evalRegs, then check the progress for each
        // add them to the appropriate state
        
        if (evalRegs) {
            // const roles = evalRegs[mc_key].roles;
            console.log(evalRegs);
            for (let r of Object.values(evalRegs.regs)) {
                if (
                    evalProgressNumbers[mc_key] && 
                    evalProgressNumbers[mc_key][r.cst_key]
                ) {
                    getUniqueDocFromDb({"path": `users/${r.cst_key}`, "once": true, "callback": callback})
                    function callback(data) {
                        processUser(data);
                    }
                } else {
                    processUser(r);
                }
                usersRef.current = {...usersRef.current, ...{[r.cst_key]: r}}
                setUsers(usersRef.current);
                // if (!roles[cst_key].includes("part")) continue;
                // getUniqueDocFromDb({"path": `users/${cst_key}`, "once": true, "callback": callback})
                // function callback(data) {
                //     usersRef.current = {...usersRef.current, ...{[data.cst_key]: data}}
                //     setUsers(usersRef.current);
                //     processUser(data);
                // }
            // }
            
                function processUser(data) {
                    const prog = (evalProgressNumbers[mc_key] && 
                        evalProgressNumbers[mc_key][data.cst_key]) 
                        ? 
                        evalProgressNumbers[mc_key][data.cst_key] 
                        : 
                        null
                    if (!prog || !prog.progNum) {
                        notStartedRef.current = {
                            ...notStartedRef.current, 
                            ...{[data.cst_key]: {
                                "user": data, 
                                "progress": prog, 
                                "mcData": mc
                            }
                        }}
                        setNotStarted(notStartedRef.current)
                    }
                    if (prog && prog.progNum < 9) {
                        inProgressRef.current = {
                            ...inProgressRef.current, 
                            ...{[data.cst_key]: {
                                "user": data, 
                                "progress": prog, 
                                "mcData": mc
                            }
                        }}
                        setInProgress(inProgressRef.current);
                        if (notStartedRef.current && notStartedRef.current[data.cst_key]) {
                            let newList = {...notStartedRef.current};
                            delete newList[data.cst_key];
                            notStartedRef.current = {...newList};
                            setNotStarted(notStartedRef.current);
                        }
                    }
                    if (prog && prog.progNum >= 9) {
                        completeRef.current = {
                            ...completeRef.current, 
                            ...{[data.cst_key]: {
                                "user": data, 
                                "progress": prog, 
                                "mcData": mc
                            }
                        }}
                        setComplete(completeRef.current);
                        if (notStartedRef.current && notStartedRef.current[data.cst_key]) {
                            let newList = {...notStartedRef.current};
                            delete newList[data.cst_key];
                            notStartedRef.current = {...newList};
                            setNotStarted(notStartedRef.current);
                        }
                    }
                    
                    setLoading(false);
                }
            }
        }
        // getNFERegistrants();
    }, [mc_key, evaluatingList, evalRegs, userProfile])

    async function getNFERegistrants() {
        const res = await UseCloudFunction(
            "getMCRegistrantsFromNFE",
            {
                "mc_key": mc_key,
                "cst_key": userProfile.cst_key,
            }
        )
        console.log(res);
        let regObj = {}
        for (let u of res.data) {
            const user_key = u.ind_cst_key;
            if ((!inProgressRef.current || !inProgressRef.current[user_key]) && (!completeRef.current || !completeRef.current[user_key])) {
                regObj[user_key] = {
                    "user": {
                        "fName": u.ind_first_name,
                        "lName": u.ind_last_name,
                        "cst_key": user_key,
                    }
                }
            }
        }
        notStartedRef.current = {...notStartedRef.current, ...regObj}
        setNotStarted(notStartedRef.current);
    }

    function goToIndividualEval(user) {
        const currentMcObj = {
            'mcData': currentMcDisplay,
            'user': user,
        }
        dispatch(setCurrentMc(currentMcObj))
        navigate(`/evaluate/${currentMcDisplay.mc_key}_${user.cst_key}`);
    }

    function cancel() {
        navigate('/home/evaluating');
    }

    return (
        <div className='view-eval-data-container'>
            <div className='view-eval-data-content'>
                
                {
                    (currentMcDisplay) 
                    ?
                    <div className='g-card'>
                        <BackButton cancel={() => cancel()} />
                        <div className="g-space-1"></div>
                        <div className='mc-title-div'>
                            <div>
                                <img src={currentMcDisplay.imageUrl} className='mc-icon' />
                            </div>
                            <div className='mc-name'>
                                {currentMcDisplay.name}
                            </div>
                        </div>
                        <hr />
                        {
                            (loading)
                            ?
                            <div className='center-col'>
                                <ThreeDotsLoader />
                            </div>
                            :
                            (
                                Object.values(inProgress).length === 0 &&
                                Object.values(complete).length === 0 &&
                                Object.values(notStarted).length === 0
                            ) &&
                            <div className='center-col'>
                                <h4>No participants registered yet...</h4>
                            </div>
                        }
                        {
                            (Object.values(inProgress).length > 0 && evaluatingList) &&
                            <div className='progress-list-div'>
                                <div className='section-title'>
                                    In Progress
                                </div>
                                {
                                    Object.values(inProgress).sort((a, b) => (a.user.lName.toLowerCase() > b.user.lName.toLowerCase()) ? 1 : (a.user.lName.toLowerCase() === b.user.lName.toLowerCase()) ? ((a.user.fName.toLowerCase() > b.user.fName.toLowerCase()) ? 1 : -1) : -1 )
                                    .map(p => {
                                        console.log(p);
                                        return (
                                            <div key={p.user.cst_key} className='g-list-item mc-card clickable' onClick={() => goToIndividualEval(p.user)}>
                                                <div className='icon-container'>
                                                    <UserIcon profile={p.user} medium={true} />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='title'>{p.user.fName} {p.user.lName}</div>
                                                    <div className='meta'>Last Edit: {format(new Date(p.progress.ts), 'Pp')}</div>
                                                </div>
                                                <div>
                                                    Status:
                                                    <div className="g-space-10"></div>
                                                    <StatusBarWithIcons progressNumber={p.progress} mcData={p.mcData} />
                                                    <div className="g-space-10"></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='g-space-2'></div>
                            </div>
                        }
                        
                        {
                            (Object.values(notStarted).length > 0 && evaluatingList) &&
                            <div className='progress-list-div'>
                                <div className='section-title'>
                                    Not Yet Started
                                </div>
                                {
                                    Object.values(notStarted)
                                    .sort((a, b) => (a.user.lName.toLowerCase() > b.user.lName.toLowerCase()) ? 1 : (a.user.lName.toLowerCase() === b.user.lName.toLowerCase()) ? ((a.user.fName.toLowerCase() > b.user.fName.toLowerCase()) ? 1 : -1) : -1 )
                                    .map(p => {
                                        // console.log(p);
                                        return (
                                            <div key={p.user.cst_key} className='g-list-item mc-card no-shadow'>
                                                <div className='icon-container'>
                                                    <UserIcon profile={p.user} medium={true} />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='title'>{p.user.fName} {p.user.lName}</div>
                                                    <div className='meta'>Registered: {format(new Date(p.user.regDate), 'Pp')}</div>
                                                </div>
                                                <div>
                                                    Status:
                                                    <div className="g-space-10"></div>
                                                    <StatusBarWithIcons progressNumber={p.progress} mcData={p.mcData} />
                                                    <div className="g-space-10"></div>
                                                </div>
                                            </div>    
                                        )
                                    })
                                }
                                <div className='g-space-2'></div>
                            </div>
                        }
                        
                        {
                            (Object.values(complete).length > 0 && evaluatingList) &&
                            <div className='progress-list-div'>
                                <div className='section-title'>
                                    Completed
                                </div>
                                {
                                    Object.values(complete).sort((a, b) => (a.user.lName.toLowerCase() > b.user.lName.toLowerCase()) ? 1 : (a.user.lName.toLowerCase() === b.user.lName.toLowerCase()) ? ((a.user.fName.toLowerCase() > b.user.fName.toLowerCase()) ? 1 : -1) : -1 )
                                    .map(p => {
                                        console.log(p);
                                        return (
                                            <div key={p.user.cst_key} className='g-list-item mc-card clickable' onClick={() => goToIndividualEval(p.user)}>
                                                <div className='icon-container'>
                                                    <UserIcon profile={p.user} medium={false} />
                                                </div>
                                                <div className='text-container'>
                                                    <div className='title'>{p.user.fName} {p.user.lName}</div>
                                                    <div className='meta'>Completed: {format(new Date(p.progress.ts), 'Pp')}</div>
                                                </div>
                                                <div>
                                                    Status:
                                                    <div className="g-space-10"></div>
                                                    <StatusBarWithIcons progressNumber={p.progress} mcData={p.mcData} />
                                                    <div className="g-space-10"></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    :
                    <div className='g-card'>
                        <div className='center-col'><ThreeDotsLoader /></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ViewEvalData;
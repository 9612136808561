import React, { useEffect, useRef, useState } from 'react'
import './admin-data.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCertificate, faCheckDouble, faDownload, faF, faFilter, faFlagCheckered, faLineChart, faPen, faRibbon, faShoppingCart, faTrash, faTrophy, faUsers, faUsersBetweenLines } from '@fortawesome/free-solid-svg-icons'
import { getCompiledDataFromDb } from '@/utils/getDataFromDb'
import DataCard from './data-card.component'
import LineChart from './line-chart.component'
import { format, setWeek } from 'date-fns'
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component'
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component'
import EditDataModal from './edit-data-modal.component'
import { useNavigate } from 'react-router-dom'
import DataFilters from './data-filters.component'
import DataDownloadModal from './data-download-modal.component'

const AdminData = ({
    users,
    mcList,
    activityLogs,
    nfeRegs
}) => {

    const [ logsForChart, setLogsForChart ] = useState(null);
    const [ tenDays, setTenDays ] = useState([]);
    const [ dataToDisplay, setDataToDisplay ] = useState([])
    const [ loading, setLoading ] = useState(true);
    const [ activeUsers, setActiveUsers ] = useState(0);
    const [ dataToEdit, setDataToEdit ] = useState(null);
    const [ filter, setFilter ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ clear, setClear ] = useState(false);
    const [ showDownloadModal, setShowDownloadModal ] = useState(false);
    const [ brokedownRegs, setBrokedownRegs ] = useState(null);
    const [ graphHeight, setGraphHeight ] = useState(0);
    const dataRef = useRef();
    const approveRef = useRef();
    const filterRef = useRef();
    const navigate = useNavigate();
    let timeout;
    let dataListener;

    useEffect(() => {
        timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);

        const boxheight = document.getElementById('graph-box').offsetHeight;
        setGraphHeight(boxheight - 57);

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        if (!nfeRegs) return;
        let arr = [];
        const tenDaysAgo = new Date();
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
        tenDaysAgo.setHours(0, 1, 0, 0); // Set to 12:01 AM
        for (let mc of Object.values(nfeRegs)) {
            for (let r of Object.values(mc)) {
                const ts = new Date(r['Registrant.reg_add_date']).getTime();
                if (ts > tenDaysAgo.getTime()) {
                    arr.push(r);
                }
            }
        }
        setBrokedownRegs(arr);
    }, [nfeRegs])

    useEffect(() => {
        if (!activityLogs) return;
        setLogsForChart(Object.values(activityLogs));
        setActiveUsers([...new Set(Object.values(activityLogs).map(d => d.user))].length);
    }, [activityLogs])

    useEffect(() => {
        // getCompiledData();
        // getTenDaysData();
        // getCompletedCount();
        // getPurchasedCount();
        getFilteredData();
    }, [users])

    // useEffect(() => {
    //     console.log(filter);
    //     if (Object.values(filter).length === 0 && users) {
    //         getTenDaysData();
    //         return;
    //     }
    // }, [filter])

    function getFilteredData() {
        if (Object.values(filter).length === 0 && users) {
            getTenDaysData();
            return;
        }
        if (dataListener) dataListener();
        dataRef.current = {};
        setDataToDisplay(Object.values(dataRef.current));
        let filterObj = {...filterRef.current};
        filterObj.callback = callback;
        filterObj.handleListener = handleListener;
        console.log(filterObj);
        getCompiledDataFromDb(filterObj);
        function callback(data) {
            dataRef.current = {
                ...dataRef.current,
                ...{[data.id]: data}
            }
            setDataToDisplay(Object.values(dataRef.current));
            // console.log(data);
            setLoading(false);
        }
        function handleListener(unsub) {
            dataListener = unsub;
        } 
    }

    

    function getTenDaysData() {
        setLoading(true);
        
        if (dataListener) dataListener();
        dataRef.current = {};
        setDataToDisplay(Object.values(dataRef.current));
        const tenDaysAgo = new Date();
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
        tenDaysAgo.setHours(0, 1, 0, 0); // Set to 12:01 AM
        getCompiledDataFromDb({
            'startDate': tenDaysAgo.getTime(),
            'callback': callback,
            'handleListener': handleListener,
        });
        function callback(data) {
            dataRef.current = {
                ...dataRef.current,
                ...{[data.id]: data}
            }
            setDataToDisplay(Object.values(dataRef.current));
            setTenDays(Object.values(dataRef.current));
            setLoading(false);
        }
        function handleListener(unsub) {
            dataListener = unsub;
        } 
    }

    function displayFilteredMcs(e) {
        filterRef.current = {};
        setFilter(filterRef.current);
        setClear(true);
        if (dataListener) dataListener();
        setDataToDisplay(
            Object.values(tenDays)
            .filter(t => t.type === e)
        )
    }

    function changeFilterOption(key, value) {
        let tempFilter = {...filterRef.current};
        if (!value) {
            delete tempFilter[key];
        } else {
            tempFilter = {
                ...tempFilter,
                ...{[key]: value}
            }
        }
        filterRef.current = {...tempFilter}
        setFilter(filterRef.current);
    }
    
    function resetData() {
        filterRef.current = {};
        setFilter(filterRef.current);
        setClear(true);
        getTenDaysData();
    }

    return (
        <div className='admin-data'>
            <div className='section-title with-icon'>
                Micro-Credential Data
                <div className='multiple-icons'>
                {
                    (users && mcList) &&
                    <>
                    <button 
                        className='g-button text'
                        onClick={() => setShowDownloadModal(true)}>
                        <FontAwesomeIcon 
                            icon={faDownload} 
                            className='icon' 
                            title='Download Data' />
                    </button>
                    
                    </>
                }
                </div>
            </div> 
            <div className="g-space-1"></div>
            <div className='data-page-content'>
                <div className='data-page-grid g-card'>
                    <div className='card-title'>10-Day User Data Summary</div>
                    <div className='data-card-div user-count'>
                        <DataCard 
                            icon={faLineChart}
                            data={logsForChart ? Object.values(logsForChart).length.toLocaleString() : []}
                            text='Total Activity'
                            onclick={() => navigate('/admin/activity')} />
                    </div>
                   
                    <div className='data-card-div active-count'>
                        <DataCard 
                            icon={faUsers}
                            data={activeUsers}
                            text='Active Users'
                            onclick={() => navigate('/admin/users')} />
                    </div>
                    <div className='data-card-div started-count'>
                        <DataCard 
                            icon={faFlagCheckered}
                            data={tenDays ? Object.values(tenDays).filter(l => l.type === 'materials-viewed').length.toLocaleString() : 0}
                            text='MCs Started'
                            onclick={() => displayFilteredMcs('materials-viewed')} />
                    </div>
                    <div className='data-card-div precheck-count'>
                        <DataCard 
                            icon={faCheckDouble}
                            data={tenDays ? Object.values(tenDays).filter(l => l.type === 'precheck-approved').length.toLocaleString() : 0}
                            text='Prechecks Approved'
                            onclick={() => displayFilteredMcs('precheck-approved')} />
                    </div>
                    <div className='data-card-div purchased-count'>
                        <DataCard 
                            icon={faShoppingCart}
                            data={brokedownRegs ? brokedownRegs.length : 0}
                            text='Purchased MCs'
                            onClick={() => true} />
                    </div>
                    <div className='data-card-div completed-count'>
                        <DataCard 
                            icon={faTrophy}
                            data={tenDays ? Object.values(tenDays).filter(l => l.type === 'portfolio-approved').length.toLocaleString() : 0}
                            text='Completed MCs'
                            onclick={() => displayFilteredMcs('portfolio-approved')} />
                    </div>
                    <div className='ten-days g-list-item' id='graph-box' >
                        <div className='chart-text'>Daily Activity Breakdown</div>
                        {
                            (logsForChart) &&
                            <LineChart 
                                recentActivity={logsForChart}
                                chartHeight={graphHeight} />
                        }
                    </div>
                </div>
                <div className="g-space-2"></div>
                <div className='card-title'>
                    User Data List
                    
                </div>
                <div className="g-space-1"></div>
                <div className="user-data-grid">
                    <div className="filter-col">
                        <DataFilters
                            users={users}
                            mcList={mcList}
                            filter={filter}
                            changeFilterOption={changeFilterOption}
                            clear={clear}
                            setClear={setClear} />
                        <div className="g-space-0-5"></div>
                        <div className='buttons stack'>
                            <button
                                className='g-button med-btn primary'
                                onClick={() => getFilteredData()}>
                                Get Filtered Data
                            </button>
                            <button 
                                className='g-button small-btn'
                                onClick={() => resetData()}>
                                Clear Filter
                                {/* <div className=' stacked-icons'>
                                    <FontAwesomeIcon icon={faFilter} />
                                    <FontAwesomeIcon icon={faBan} />
                                </div> */}
                            </button>
                        </div>
                    </div>
                    {/* <div className="g-space-1"></div> */}
                    <div className='data-list'>
                        <table className='g-table'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>State</th>
                                    <th>MC</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (loading)
                                    ?
                                    <tr>
                                        <td colSpan="5">
                                            <ThreeDotsLoader />
                                        </td>
                                    </tr>
                                    :
                                    (dataToDisplay.length > 0 && mcList && users) 
                                    ?
                                    dataToDisplay
                                    .sort((a,b) => a.ts < b.ts ? 1 : -1)
                                    .map((a, i) => {
                                        // console.log(a);
                                        const user = users[a.cst_key];
                                        const mcData = mcList[a.mc_key];
                                        if (!user) {
                                            console.log(`missing user: ${a.cst_key}`);
                                            return;
                                        }
                                        if (!mcData) {
                                            console.log(`missing MC: ${a.mc_key}`);
                                            return;
                                        }
                                        return (
                                        <tr key={a.id}>
                                            <td>{a.ts ? format(new Date(a.ts), "Pp") : 'no date'}</td>
                                            <td>{users[a.cst_key].fName} {users[a.cst_key].lName}</td>
                                            <td>{users[a.cst_key].state}</td>
                                            <td>{mcList[a.mc_key].name}</td>
                                            <td>{a.type}</td>
                                            <td>
                                                <button 
                                                    className='g-button small-btn'
                                                    onClick={() => setDataToEdit(a)}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            </td>
                                        </tr>
                                    )})
                                    : 
                                    <tr>
                                        <td colSpan="5">
                                            {
                                                (Object.values(filter).length > 0)
                                                ?
                                                <span>No data in the last 10 days...</span>
                                                :
                                                <span>No filtered data available...</span>
                                            }
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {
                            (loading)
                            
                        }
                    </div>
                </div>
            </div>
            <EditDataModal
                show={dataToEdit}
                cancel={() => setDataToEdit(null)}
                dataPoint={dataToEdit}
                users={users}
                mcList={mcList} />
            <DataDownloadModal
                show={showDownloadModal}
                cancel={() => setShowDownloadModal(false)}
                users={users}
                mcList={mcList} />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
        </div>
    )
}

export default AdminData
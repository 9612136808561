import { faFile, faFilePdf, faImage, faMicrophone, faTableCells, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function findIcon(type) {
	if (type.indexOf('image') > -1) {
		var icon = <FontAwesomeIcon icon={faImage} />;
	} else if (type.indexOf('pdf') > -1) {
		var icon = <FontAwesomeIcon icon={faFilePdf} />;
	} else if (type.indexOf('audio') > -1) {
		var icon = <FontAwesomeIcon icon={faMicrophone} />;
	} else if (type.indexOf('video') > -1) {
		var icon = <FontAwesomeIcon icon={faVideoCamera} />;
	} else if (type.indexOf('csv') > -1) {
		var icon = <FontAwesomeIcon icon={faTableCells} />;
	} else {
		var icon = <FontAwesomeIcon icon={faFile} />;
	}
	return icon;
}
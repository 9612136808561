import React, { useEffect, useState } from 'react';
import './progress-display-container.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompiledDataFromDb, getProgressFromDb } from '@/utils/getDataFromDb';
import ProgressHeader from '@/components/SHARED/progress-header/progress-header.component';
import BackButton from '@/components/back-button/back-button.component';
import ProgressContentItem from './progress-content-item.component';
import { format } from 'date-fns';

const ProgressDisplayContainer = ({
    users,
    mcList
}) => {

    const { progressId } = useParams();
    const [ progress, setProgress ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ mcData, setMcData ] = useState(null);
    const [ content, setContent ] = useState(null);
    const [ progressValue, setProgressValue ] = useState(0);
    const [ approvalValue, setApprovalValue ] = useState(0);
    const [ mcStatus, setMcStatus ] = useState(null)
    let progressListener;
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            if (progressListener) progressListener();
        }
    }, [])

    useEffect(() => {
        if (!progressId) return;
        getProgress();
    }, [progressId])

    useEffect(() => {
        if (!mcData || !progress) return;
        setUpContent();
        getStatus();
    }, [mcData, progress])

    function getProgress() {
        let mc_key = progressId.split('_')[0];
        let cst_key = progressId.split('_')[1];
        setUser(users[cst_key]);
        setMcData(mcList[mc_key]);
        getProgressFromDb({
            'cst_key': cst_key,
            'mc_key': mc_key,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            setProgress(data);
        }
        function handleListener(unsub) {
            progressListener = unsub;
        }

        // getHistory();
    }

    function getHistory() {
        // getCompiledDataFromDb({
        //     ''
        // })
    }

    function setUpContent() {
        console.log(mcData);
        let tempContent = [
            {
                'num': -1,
                'id': 'materials',
                'name': 'Viewed Required Micro-Credential Materials',
                'desc': '',
                'type': 'content'
            },
            {
                'num': 0,
                'id': 'precheck',
                'name': 'Precheck Artifact and Self-Analysis',
                'desc': '',
                'type': 'content'
            },
            {
                'num': 1,
                'id': 'overview',
                'name': 'Overview Questions',
                'desc': mcData.overview,
                'type': 'content'
            }
        ]
        let elementCount = 2;
        let contentCount = 2;
        for (let eo of Object.values(mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)) {
            tempContent.push({
                'num': elementCount,
                'id': eo.id,
                'name': eo.name,
                'desc': eo.desc,
                'type': 'header'
            })
            elementCount++;
            if (eo.comp) {
                for (let comp of Object.values(eo.comp).sort((a,b) => a.id > b.id ? 1 : -1)) {
                    tempContent.push({
                        'num': elementCount,
                        'id': comp.id,
                        'name': comp.name,
                        'desc': comp.desc,
                        'type': 'content'
                    })
                    contentCount++;
                    elementCount++;
                }
            }
        }
        tempContent.push({
            'num': elementCount,
            'id': 'closing',
            'name': 'Closing Reflection',
            'desc': mcData.closing,
            'type': 'content'
        })
        contentCount++;
        elementCount++;
        console.log(tempContent);
        setContent(tempContent);
        let completeCount = 0;
        let approvedCount = 0;
        let approved = []
        for (let p of Object.keys(progress)) {
            if (p.includes('content_')) {
                completeCount++;
                const id = p.replace('content_', '');
                if (
                    progress[p].approved ||
                    (
                        progress[`eval_${id}`] &&
                        (
                            progress[`eval_${id}`].approved ||
                            (
                                progress[`eval_${id}`].completed &&
                                progress[`eval_${id}`].explained &&
                                progress[`eval_${id}`].located
                            )
                        )
                    )
                ) {
                    approvedCount++;
                    approved.push(id);
                }
            }
        }
        console.log(approvedCount);
        console.log(approved);
        const compPercent = (((completeCount - 1)/contentCount) * 100).toFixed(0).toLocaleString();
        setProgressValue(compPercent);
        const apprPercent = ((approvedCount/contentCount) * 100).toFixed(0).toLocaleString();
        setApprovalValue(apprPercent);
        document.getElementById("complete-progress").style.width = compPercent + "%";
        document.getElementById("approved-progress").style.width = apprPercent + "%";
        
    }

    function getStatus() {
        if (progress.approved) {
            setMcStatus({
                'text': 'complete',
                'date': `Approved: ${format(new Date(progress.lastEval), "Pp")}`
            })
        } else if (progress.pending) {
            setMcStatus({
                'text': 'pending',
                'date': `Submitted: ${format(new Date(progress.lastEdit), "Pp")}`
            })
        } else if (progress.declined) {
            setMcStatus({
                'text': 'declined',
                'date': `Evaluated: ${format(new Date(progress.lastEval), "Pp")}`
            })
        } else {
            setMcStatus({
                'text': 'in progress',
                'date': `Last Edit: ${format(new Date(progress.lastEdit), "Pp")}`
            })
        }
    }

    return (
        <div className='g-card progress-display-container'>
            <BackButton cancel={() => navigate(-1)} />
            <div className="g-space-2"></div>
            {
                (user && mcData) &&
                <ProgressHeader
                    user={user}
                    mcData={mcData} />
        
            }
            <div className="g-space-1"></div>
            <section className='progress-bars'>
                <div className='g-list-item'>
                    MC Status
                    {
                        (mcStatus) &&
                        <>
                        <div className="g-space-0-5"></div>
                        <div className={`status ${mcStatus.text.replace(' ', '')}`}>
                            {mcStatus.text}
                        </div>
                        <div className='meta'>{mcStatus.date}</div>
                        </>
                    }
                </div>
                <div className='progress-with-desc g-list-item'>
                    User Completion
                    <div className="g-space-0-5"></div>
                    <div className='progress-container'>
                        <div className="progress">
                            <div 
                                className="progress-bar" 
                                id='complete-progress' 
                                aria-valuenow={progressValue} 
                                min="0" 
                                max="100" >
                            </div>
                        </div>
                        <div className='meta'>{progressValue}%</div>
                    </div>
                </div>
                <div className='progress-with-desc g-list-item'>
                    Evaluator Approval
                    <div className="g-space-0-5"></div>
                    <div className='progress-container'>
                        <div className="progress">
                            <div 
                                className="progress-bar"
                                id='approved-progress' 
                                aria-valuenow={approvalValue} 
                                min="0" 
                                max="100" >
                            </div>
                        </div>
                        <div className='meta'>{approvalValue}%</div>
                    </div>
                </div>
            </section>
            <div className="g-space-2"></div>
            {
                (content) &&
                <section className='mc-content'>
                    {
                        content.map(c => (
                            <div key={c.id}>
                                <ProgressContentItem
                                    content={c}
                                    allContent={content}
                                    progress={progress} />
                            </div>
                        ))
                    }
                </section>
            }
        </div>

    )
}

export default ProgressDisplayContainer
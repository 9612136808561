import React, { useEffect, useState } from 'react';
import './portfolio-upload-modal.styles.scss';
import Modal, { ModalAlert } from '@/components/SHARED/modal/modal.component';
import UploadForm from '../../upload-form/upload-form.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

const PortfolioUploadModal = ({
    mcData,
    mcProgress,
    showUploadModal,
    setShowUploadModal,
    saveArtifactChoice,
    selectedTempArtifact,
    setSelectedTempArtifact
}) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ uploadModalHeight, setUploadModalHeight ] = useState(0);
    const [ modalElement, setModalElement ] = useState(null);
    const [ modalOption, setModalOption ] = useState(null);
    const [ file, setFile ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ clear, setClear ] = useState(false);
    let interval;
    const storage = getStorage();
    
    useEffect(() => {
        // interval = setInterval(() => {
            // findAndSetModalHeight();
        // }, 20)
        // const modalMain = document.getElementById('basic-modal-portfolio-upload');
        // console.log(modalMain)
        // const modalHeight = modalMain.offsetHeight;
        // setUploadModalHeight(modalHeight - 220);
    }, [])

    useEffect(() => {
        // findAndSetModalHeight();
    }, [modalOption])

    useEffect(() => {
        if (!modalElement) return;
        const modalHeight = modalElement.offsetHeight;
        console.log(modalHeight);
        console.log(modalHeight - 176);
        setUploadModalHeight(modalHeight - 176);
    }, [modalElement])

    function findAndSetModalHeight() {
        const modalMain = document.getElementById('basic-modal-portfolio-upload');
        if (modalMain) {
            console.log(modalMain);
            setModalElement(modalMain);
            clearInterval(interval);
        }
    }

    async function uploadArtifact() {
        const uploadName = document.getElementById('upload-label').value;
        if (!file) {
            setAlertMessage('Please upload a valid file before continuing.')
            return;
        }
        if (!uploadName) {
            setAlertMessage('Please give this upload a name before continuing.')
            return;
        }
        setLoading(true);
        const now = new Date().getTime();
        const pathname = `${mcData.mc_key}_${profile.cst_key}/${now}-${file.name}`
        const uploadRef = ref(storage, pathname)
        const metadata = {
            contentType: file.type,
            customMetadata: {
                cst_key: profile.cst_key,
                mc_key: mcData.mc_key,
            }
        };
        try {
            await uploadBytes(uploadRef, file, metadata)
        } catch (err) {
            setAlertMessage(`Something went wrong uploading your file. Please try again later. (${err})`);
            return;
        }
        const uploadObj = {
            'link': pathname,
            'name': uploadName,
            'timestamp': now,
            'type': file.type,
            'id': now + '-upload',
        }
        const res = await UseCloudFunction(
            'saveUploadedArtifact', 
            {
                'uploadObj': uploadObj, 
                'cst_key': profile.cst_key, 
                'mc_key': mcData.mc_key
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong uploading your file. Please try again later. (${res.error})`);
            setLoading(false);
            return;
        }
        saveArtifactChoice(uploadObj.id);
        clearFormAndClose();
    }

    function saveSelectedEvidenceToDb(id) {
        saveArtifactChoice(id);
        clearFormAndClose();
    }

    function clearFormAndClose() {
        setModalOption(null);
        setLoading(false);
        // setClear(true);
        setShowUploadModal(false);
        setSelectedTempArtifact(null);
    }

    return (
        <div className='portfolio-upload-modal'>
            <Modal 
                show={showUploadModal} 
                cancel={() => setShowUploadModal(false)} 
                cls='wide' 
                text='Choose an Existing Upload or Add a New One'
                idCode='portfolio-upload' >
                <div className='upload-modal-content'>
                    
                    {
                        (!modalOption)
                        ?
                        <div className='option-buttons'>
                            <div className='button-grid'>
                                <button 
                                    className='g-button'
                                    onClick={() => setModalOption('choose')}>
                                    <FontAwesomeIcon icon={faHandPointUp} />
                                    Choose an Existing Artifact
                                </button>
                                <button 
                                    className='g-button'
                                    onClick={() => setModalOption('upload')}>
                                    <FontAwesomeIcon icon={faUpload} />
                                    Upload a New Artifact
                                </button>
                            </div>
                        </div>
                        :
                        // ((uploadModalHeight > 0) &&
                        <div 
                            className='form-div'
                            style={{'maxHeight': '300px'}}
                            >
                            <UploadForm 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                                cancel={() => clearFormAndClose()} 
                                setAlertMessage={setAlertMessage} 
                                chooseArtifact={saveArtifactChoice}
                                selectedTempArtifact={selectedTempArtifact}
                                setSelectedTempArtifact={setSelectedTempArtifact}
                                option={modalOption}
                                clear={clear}
                                setClear={setClear}
                                file={file}
                                setFile={setFile} />
                        </div>
                    }
                    <div className='g-space-1'></div>
                    <div className='buttons'>
                        {
                            (loading)
                            ?
                            <>
                                <button className='g-button'>Cancel</button>
                                <button className='g-button'><ThreeDotsLoader /></button>
                            </>
                            :
                            (modalOption === 'upload') 
                            ?
                            <>
                                <button 
                                    className='g-button' 
                                    onClick={() => clearFormAndClose()}>
                                    Cancel
                                </button>
                                <button 
                                    className='g-button primary' 
                                    onClick={() => uploadArtifact()} >
                                    Upload and Choose Artifact
                                </button>
                            </>
                            :
                            (modalOption === 'choose') 
                            ?
                            <>
                                <button 
                                    className='g-button' 
                                    onClick={() => clearFormAndClose()}>
                                    Cancel
                                </button>
                                <button 
                                    className={`g-button ${!selectedTempArtifact ? 'disabled' : 'primary'}`}
                                    onClick={() => saveSelectedEvidenceToDb(selectedTempArtifact)}>
                                    Choose Selected Artifact
                                </button>
                            </>
                            :
                            <button 
                                className='g-button' 
                                onClick={() => clearFormAndClose()}>
                                Cancel
                            </button>
                        }
                    </div>
                </div>
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default PortfolioUploadModal
import React, { useEffect, useRef, useState } from 'react'
import './evaluate-page.styles.scss';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import HeadBar from '@/components/layout-auth/head-bar/head-bar.component';
import BackButton from '@/components/back-button/back-button.component';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMc } from '@/state/slices/contentSlice';
import EvalSummary from '../../components/EVAL/summary/eval-summary.component';
import EvalPrecheck from '../../components/EVAL/precheck/eval-precheck.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faRightLong, faUpload } from '@fortawesome/free-solid-svg-icons';
import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { checkStatus } from '@/components/EVAL/determineStatus';
import EvalSidebar from '@/components/EVAL/eval-sidebar/eval-sidebar.component';
import MobileHeadBar from '@/components/layout-auth/head-bar/mobile-head-bar.component';
import { getProgressFromDb } from '@/utils/getDataFromDb';
import EvalOverviewClosing from '@/components/EVAL/eval-overview/eval-overview-closing.component';
import EvalDetail from '@/components/EVAL/eval-detail/eval-detail.component';
import { scrollIntoViewIfHidden } from '@/utils/scrollIntoViewIfHidden';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { format } from 'date-fns';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import ReactJoyride from 'react-joyride';
import ProgressHeader from '@/components/SHARED/progress-header/progress-header.component';

const EvaluatePage = () => {

    const location = useLocation();
    const screenSize = useSelector(state => state.contentData.screenSize);
    const currentData = useSelector(state => state.contentData.currentMc);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ progress, setProgress ] = useState(null);
    const [ activeId, setActiveId ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ content, setContent ] = useState(null);
    const [ user_key, setUser_key ] = useState(null);
    const [ mc_key, setMc_key ] = useState(null);
    const [ viewUploads, setViewUploads ] = useState(false);
    const [ progressValue, setProgressValue ] = useState(0);
    const [ showProgress, setShowProgress ] = useState(false);
    const [ detailList, setDetailList ] = useState(null);
    const [ readyToRelease, setReadyToRelease ] = useState(false);
    const [ dirtyStatus, setDirtyStatus ] = useState({});
    const [ submissionId, setSubmissionId ] = useState(null);
    const [ submissionDate, setSubmissionDate ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ steps, setSteps ] = useState([])
    const { user_mc_keys } = useParams();
    let progressListener;
    const dirtyRef = useRef({});
    const approveRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let timeout;
    const styles = {
        options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: 'var(--primary)',
            textColor: 'var(--primary)',
        },
    }

    useEffect(() => {
        if (!user_mc_keys) {return;}
        const mcKey = user_mc_keys.substring(0, user_mc_keys.indexOf('_'));
        setMc_key(mcKey);
        const userKey = user_mc_keys.substring(user_mc_keys.indexOf('_') + 1)
        setUser_key(userKey);
    }, [user_mc_keys])

    useEffect(() => {
        if (!currentData || !mc_key || !user_key || !progress) return;
        if (currentData.mcData.mc_key !== mc_key || currentData.user.cst_key !== user_key) {
            // navigate(-1);
            return;
        }
        setUpContent()
        const submission = progress.submitted ? 
            Object.values(progress.submitted).sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)[0] :
            null;
        if (!submission) return;
        setSubmissionId(`${submission.timestamp}_submission`);
        setSubmissionDate(format(new Date(submission.timestamp), "Pp"));
    }, [currentData, mc_key, user_key, progress])

    useEffect(() => {
        if (!mc_key || !user_key || !currentData) return;
        getCurrentProgress();
        
        return () => {
            // dispatch(setCurrentMc(null));
            if (progressListener) {
                console.log('detaching progress listener')
                progressListener();
            }
        }
    }, [mc_key, user_key, currentData])
    
    useEffect(() => {
        if (!location || !mc_key || !user_key) return;
        // console.log(location.pathname);
        const p = location.pathname;
        let id = p.replace(`/evaluate/${mc_key}_${user_key}`, '');
        // console.log(id);
        let tempId;
        if (id.includes('/')) {
            tempId = id.slice(1);
            id = tempId;
        } else {
            id = 'summary';
        }
        setActiveId(id);
        scrollIntoViewIfHidden(document.getElementById('topofpage'));
    }, [location, mc_key, user_key])

    useEffect(() => {
        if (!progress) return;
        setUpContent();
    }, [progress])

    function getCurrentProgress() {
        console.log('getting progress')
        getProgressFromDb({'cst_key': user_key, 'mc_key': mc_key, 'callback': callback, 'handleListener': handleListener});
        function callback(data) {
            // let tempData = {...currentData};
            // tempData.progress = data
            // dispatch(setCurrentMc(tempData));
            setProgress(data);
            // alert('new progress');
            // console.log(tempData);
            // setUpContent();
        }
        function handleListener(unsub) {
            progressListener = unsub;
        }
    }

    function saveDirtyStatus(id, status) {
        dirtyRef.current = {...dirtyRef.current, ...{[id]: status}};
        setDirtyStatus(dirtyRef.current);
    }

    function setUpContent() {
        let tempContent = [
            {
                'num': -2,
                'id': 'materials',
                'name': 'Viewed Required Micro-Credential Materials'
            },
            {
                'num': -1,
                'id': 'precheck',
                'name': 'Precheck Artifact and Self-Analysis'
            },
            {
                'num': 0,
                'id': 'overview',
                'name': 'Overview Questions'
            }
        ]
        let elementCount = 2;
        for (let eo of Object.values(currentData.mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)) {
            tempContent.push(eo);
            if (eo.comp) {
                elementCount += Object.values(eo.comp).length;
            }
        }
        tempContent.push({
            'num': Object.values(currentData.mcData.content).length + 1,
            'id': 'closing',
            'name': 'Closing Reflection'
        })
        elementCount++;
        setContent(tempContent);
        let completeEvalCount = 0;
        for (let p of Object.keys(progress)) {
            if (p.includes('eval_')) {
                const id = p.replace('eval_', '');
                if (progress[`content_${id}`].timestamp <= progress[p].timestamp + 2) {
                    completeEvalCount++;
                } 
                else {
                    console.log(id);
                    console.log(progress[`content_${id}`].timestamp, format(new Date(progress[`content_${id}`].timestamp), "Pp"));
                    console.log(progress[p].timestamp, format(new Date(progress[p].timestamp), "Pp"));
                }               
            }
        }

        const progPercent = ((completeEvalCount/elementCount) * 100).toFixed(0).toLocaleString();
        setProgressValue(progPercent)
        document.querySelector(".progress-bar").style.width = progPercent + "%";
        if (completeEvalCount === elementCount && progress.pending) {
            setReadyToRelease(true);
            scrollIntoViewIfHidden(document.getElementById('head-bar'));
            timeout = setTimeout(() => {
                setSteps([
                    {
                        target: '.submit-button',
                        content: 'Your Evaluation is complete! Click this button to release your results to the participant.',
                        disableBeacon: true,
                        placement: 'left',
                    }
                ])
            }, 500);
        } else {
            setReadyToRelease(false);
        }
    }

    function cancel() {
        if (location.pathname === `/home/evaluating/${mc_key}`) {
            navigate(-1);
        } else {
            navigate(`/home/evaluating/${mc_key}`);
        }
    }

    function releaseFinalEvaluation() {
        let approved = true;
        Object.keys(progress)
        .filter(k => k.includes('eval_'))
        .map(k => {
            const evalData = progress[k];
            if (!evalData.completed || !evalData.located || !evalData.explained) {
                approved = false;
                return;
            }
        });
        setConfirmMessage(`You have determined that the portfolio is ${approved ? 'APPROVED' : 'NOT APPROVED'}. The participant will be notified of this decision immediately${approved ? '.' : `, and they will have ${progress.submissionsRemaining} attempt(s) remaining.`} This decision cannot be undone. If this is correct, click "OK" to continue.`);
        approveRef.current = () => {
            continueRelease();
        }


        
        async function continueRelease() {
            navigate(`/evaluate/${currentData.mcData.mc_key}_${currentData.user.cst_key}`)
            setLoading(true);
            const tempData = {...currentData};
            let mess = '';
            let body = '';
            if (approved) {
                mess = 'SUCCESSFULLY COMPLETED';
                body = `<p>${tempData.user.fName} ${tempData.user.lName},</p><p><b>CONGRATULATIONS!</b> Your evaluator has completed assessing your evidence and reflections in the ELN Micro-Credential <b>${tempData.mcData.name}</b>. Your submission has been APPROVED!</p>
                <p>To view the evaluator's comments on this Micro-Credential or to access your uploads, please click the button below to go to the ELN MC Portal.</p>`;
            } else {
                mess = 'NOT YET COMPLETED'
                body = `<p>${tempData.user.fName} ${tempData.user.lName},</p><p>Your evaluator has completed assessing your evidence and reflections in the ELN Micro-Credential titled <b>${tempData.mcData.name}</b> and has deemed it INCOMPLETE. Your evaluator has left clear explanations in the comments sections of the Essential Outcome Details that are lacking, and you can resubmit evidence and explanations for those areas.</p>
                <p>For more information on this decision and to continue your work on this Micro-Credential, please click the button below to go to the ELN Micro-Credential Portal.</p>`
            }
            const emailData = {
                email: tempData.user.email,
                subject: `Micro-Credential Status Updated: ${mess} [MC: ${tempData.mcData.name}]`,
                title: `Your Micro-Credential Status for MC titled ${tempData.mcData.name} has been updated to <br /><br />${mess}.`,
                // get eval emails on the back end
                body: body,
            }
            const res = await UseCloudFunction(
                'releaseFinalEvaluation', 
                {
                    'user': tempData.user,
                    'mcData': tempData.mcData,
                    'cst_key': userProfile.cst_key,
                    'submissionId': submissionId,
                    'emailData': emailData,
                }
            )
            console.log(res);
            setLoading(false);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                return;
            }
            
            for (let k of Object.keys(progress)) {
                if (k.includes('eval_')) {
                    const id = k.split('_')[1];
                    window.localStorage.removeItem(`eval_${id}_${tempData.mcData.mc_key}`);
                }
            }
            setAlertMessage('Your decision has been saved correctly!');
            
        }
    }

    return (
        <div className='eval-page'>
            {
                (screenSize === 'desktop' || screenSize === 'largeTablet')
                ?
                <HeadBar />
                :
                <MobileHeadBar />
            }
            <div className={`eval-page-container ${screenSize}`}>
                
                <div className='eval-page-content'>
                    <div className={`eval-page-grid ${screenSize}`}>
                        {
                            (screenSize === 'desktop' || screenSize === 'largeTablet') &&
                            <div className='eval-sidebar-div'>
                                <div><BackButton cancel={() => cancel()} /></div>
                                <div className='g-card'>
                                    <h4>Menu</h4>
                                    <hr className='no-margin' />
                                    <div className='menu-container'>
                                        {
                                            (progress && currentData && activeId && content && dirtyStatus) &&
                                            <EvalSidebar progress={progress} currentData={currentData} activeId={activeId} content={content} dirtyStatus={dirtyStatus} />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={`eval-main ${screenSize}`}>
                            <div className='g-card' id='head-box'>
                                {
                                    (screenSize === 'mobile') &&
                                    <div className='right-col'><BackButton cancel={() => navigate(-1)} x={true} /></div>
                                }
                                <ProgressHeader
                                    user={currentData.user}
                                    mcData={currentData.mcData} />
                                <div className='eval-summary-div'>
                                    Evaluation Progress:
                                    <div className='progress-container'>
                                        <div className="progress">
                                            <div className="progress-bar" aria-valuenow={progressValue} min="0" max="100" >
                                            </div>
                                        </div>
                                        <div className='meta'>{progressValue}%</div>
                                    </div>
                                    <div className='button-row'>
                                        <div className='left-buttons'>
                                            <button 
                                                className='g-button small-btn' 
                                                onClick={() => setViewUploads(true)}>
                                                View Uploads
                                                <FontAwesomeIcon icon={faUpload} />
                                            </button>
                                            {
                                                (submissionDate) &&
                                                <div className='meta'>Portfolio Submitted:<br />{submissionDate}</div>
                                            }
                                            {/* <button className='g-button small-btn' onClick={() => setShowProgress(true)}>Progress<FontAwesomeIcon icon={faList} /></button> */}
                                        </div>
                                        {
                                            (readyToRelease && progress)
                                            ?
                                            <button className='g-button primary submit-button' onClick={() => releaseFinalEvaluation()}>Release Completed Evaluations to Participant<FontAwesomeIcon icon={faRightLong} /></button>
                                            :
                                            (progress && progress.declined && submissionId)
                                            ?
                                            <div className='g-list-item declined'>
                                                Micro-Credential Declined
                                                <div className='meta'>Declined: {format(new Date(progress.submitted[submissionId].retTime), 'P')}</div>
                                            </div>
                                            :
                                            (progress && progress.approved && submissionId)
                                            ?
                                            <div className='g-list-item approved'>
                                                Micro-Credential APPROVED
                                                <div className='meta'>Approved: {format(new Date(progress.submitted[submissionId].retTime), 'P')}</div>
                                            </div>
                                            :
                                            <button className='g-button disabled small-btn'>Release Completed Evaluations to Participant<FontAwesomeIcon icon={faRightLong} /></button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <a id="topofpage"></a>
                            <div className='g-card'>
                                
                                <Routes>
                                    <Route path='' element={<EvalSummary progress={progress} currentData={currentData} content={content} dirtyStatus={dirtyStatus} />}></Route>
                                    {/* <Route path='precheck' element={<EvalDetail currentData={currentData} content={content} saveDirtyStatus={saveDirtyStatus} />}></Route> */}
                                    {/* <Route path='overview' element={<EvalOverviewClosing currentData={currentData} activeId={activeId} />}></Route>
                                    <Route path='closing' element={<EvalOverviewClosing currentData={currentData} activeId={activeId} />}></Route> */}
                                    <Route path=':detailId' element={<EvalDetail allProgress={progress} currentData={currentData} content={content} activeId={activeId} saveDirtyStatus={saveDirtyStatus} />}></Route>
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={viewUploads} cancel={() => setViewUploads(false)} text='View Uploads' closeButton={true}  >
                <div className='upload-modal'>
                {
                    (progress && progress.uploads) &&
                    Object.values(progress.uploads).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    .map(u => (
                        <div key={u.timestamp} className='upload-item'>
                            <EvidenceButton evidenceData={u} />
                        </div>
                    ))
                }
                </div>
            </Modal>
            <Modal show={showProgress} cancel={() => setShowProgress(false)} text={`${currentData.user.name}'s Progress`} closeButton={true} >
                <EvalSummary progress={progress} currentData={currentData} closeModal={() => setShowProgress(false)} />
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(false)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(false)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
            <ReactJoyride steps={steps} scrollToFirstStep={true} styles={styles} />
        </div>
    )
}

export default EvaluatePage
import React, { useEffect, useRef, useState } from 'react';
import './data-download-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import CsvDownload from 'react-json-to-csv';
import DataFilters from './data-filters.component';
import { getCompiledDataFromDb } from '@/utils/getDataFromDb';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { format } from 'date-fns';

const DataDownloadModal = ({
  show,
  cancel,
  users,
  mcList
}) => {
  
  const [ filter, setFilter ] = useState({});
  const [ alertMessage, setAlertMessage ] = useState(null);
  const [ confirmMessage, setConfirmMessage ] = useState(null);
  const [ compiledData, setCompiledData ] = useState(null);
  const [ clear, setClear ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ headers ] = useState([
    'date',
    'fName',
    'lName',
    'state',
    'mc_name',
    'type',
    'id',
    'mc_key',
    'ts',
    'cst_key',
    'progressId'
  ])
  const approveRef = useRef();
  const filterRef = useRef({'callback': callback});

  useEffect(() => {
    if (!show) {
      clearFilter();
      setLoading(false);
    }
  }, [show])

  useEffect(() => {
    if (!compiledData) return;
    document.getElementById('csv-button').click();
    clearFilter();
    setCompiledData(null);
  }, [compiledData])

  function changeFilterOption(key, value) {
    let tempFilter = {...filterRef.current};
    if (!value) {
        delete tempFilter[key];
    } else {
        tempFilter = {
            ...tempFilter,
            ...{[key]: value}
        }
    }
    filterRef.current = {...tempFilter}
    setFilter(filterRef.current);
  }

  function getCompiledDataForDownload() {
    console.log(filterRef.current);
    if (Object.values(filterRef.current).length < 2) {
      setConfirmMessage('You will be downloading all of the data from the beginning of the Micro-Credential program. This is a great deal of data and it may take some time to download. You cannot close this page during this process. Are you sure you want do continue without filters?');
      approveRef.current = () => {
        continueDownload();
      }
    } else {
      continueDownload();
    }

    function continueDownload() {
      console.log('continuing');
      setLoading(true);

      const newFilter = {
        ...{'once': true},
        ...filterRef.current
      }
      console.log(newFilter);
      // return;
      getCompiledDataFromDb(newFilter);      
    }
  }

  function callback(data) {
    console.log(data);
    let compiledArr = [];
    for (let dataPoint of Object.values(data)) {
        let user = users[dataPoint.cst_key];
        let mc = mcList[dataPoint.mc_key];
        if (dataPoint.ts) {
            dataPoint.date = format(new Date(dataPoint.ts), "Pp");
        } else {
            dataPoint.date = 'unknown';
        }
        dataPoint.fName = user ? user.fName : 'unknown';
        dataPoint.lName = user ? user.lName : 'unknown';
        dataPoint.state = user ? user.state : 'unknown';
        dataPoint.mc_name = mc ? mc.name : 'unknown';
        compiledArr.push(dataPoint);
    }
    setCompiledData(compiledArr);
    console.log(compiledArr);
    cancel();
  }

  function clearFilter() {
    filterRef.current = {'callback': callback}
    setFilter(filterRef.current);
    setClear(true);
  }

  return (
    <div className='data-download-modal'>
      <Modal
        show={show}
        cancel={cancel}
        text='Download Data'
        cls='narrow'
        closeButton={true} >
        {
          (users && mcList) &&
          <div className='data-download-modal-content'>
            <p>Filter the data you want to download.</p>
            <div className='buttons'>
              <button 
                className='g-button small-btn'
                onClick={() => clearFilter()}>
                Clear Filter
              </button>
            </div>
            <div className="g-space-1"></div>
            <DataFilters
              users={users}
              mcList={mcList}
              filter={filter}
              changeFilterOption={changeFilterOption}
              clear={clear}
              setClear={setClear} />
            <div className='g-space-2'></div>
            <div className='buttons'>
              {
                (loading)
                ?
                <button className='g-button disabled'>
                  <ThreeDotsLoader />
                </button>
                :
                <button 
                  className='g-button primary'
                  onClick={() => getCompiledDataForDownload()} >
                  Download Selected Data
                </button>
                }
              <div className='hide'>
                  <CsvDownload
                      id="csv-button"
                      data={compiledData}
                      delimiter=","
                      filename='MC Full Data Download.csv' />
              </div>
            </div>
          </div>
        }
      </Modal>
      <ModalAlert
        show={alertMessage}
        cancel={() => setAlertMessage(null)}
        message={alertMessage} />
      <ModalConfirm
        show={confirmMessage}
        cancel={() => setConfirmMessage(null)}
        message={confirmMessage}
        onApprove={approveRef.current} />
    </div>
  )
}

export default DataDownloadModal;
import React, { useEffect, useRef, useState } from 'react'
import './pending-mcs.styles.scss';
import { getCollectionQueryFromDb } from '@/utils/getDataFromDb';
import StatusBarWithIcons from '@/components/HOME/my-microcredentials/status-bar-with-icons.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const PendingMcs = ({
    users,
    mcList,
    progressNumbers,
}) => {

    const [ pendingList, setPendingList ] = useState(null);
    const [ sortCategory, setSortCategory ] = useState('mc_name');
    const listRef = useRef();
    let listListener;
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            if (listListener) listListener();
        }
    }, [])

    useEffect(() => {
        console.log(progressNumbers);
    }, [progressNumbers])

    useEffect(() => {
        if (!mcList) return;
        getPendingMcs();
    }, [mcList])

    function getPendingMcs() {
        getCollectionQueryFromDb({
            'path': 'progress',
            'param1': {
                'key': 'pending',
                'condition': '==',
                'value': true
            },
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data, id) {
            console.log(data)
            data.id = id;
            const mcData = mcList[data.mc_key];
            data.mc_name = mcData.name;
            data.imageUrl = mcData.imageUrl;
            data.mcData = mcData;
            listRef.current = {
                ...listRef.current,
                ...{[id]: data}
            }
            setPendingList(listRef.current);
        }
        function handleListener(unsub) {
            listListener = unsub;
        }
    }

    return (
        <div className='pending-mcs'>
            <div className='pending-mcs-content'>
                <div className='section-title'>
                    Pending Micro-Credentials
                </div>
                <hr />
                <div className='buttons'>
                    <button 
                        className='g-button small-btn'
                        onClick={() => setSortCategory(sortCategory === 'mc_name' ? 'lastEdit' : 'mc_name')}>
                        {
                            (sortCategory === 'mc_name')
                            ?
                            'Sort by Submit Date'
                            :
                            'Sort by MC Name' 
                        }
                    </button>
                </div>
                <div className="g-space-0-5"></div>
                <div className='pending-list'>
                {
                    (pendingList && progressNumbers && users && sortCategory) &&
                    Object.values(pendingList)
                    .sort((a,b) => a[sortCategory] > b[sortCategory] ? 1 : -1)
                    .map(p => {
                        const user = users[p.cst_key];
                        return (
                            <div key={p.id} className='g-list-item' >
                                <div className=' progress-bar'>
                                    <div className='title-box'>
                                        <img src={p.imageUrl} />
                                        <div>
                                            <div>{p.mc_name}</div>
                                            <Link to={`/admin/userdata/${p.cst_key}`}>
                                                <div className='user-name'>{user.fName} {user.lName}</div>
                                            </Link>
                                            <div className='meta'>Submitted {format(new Date(p.lastEdit), "Pp")}</div>
                                        </div>
                                    </div>
                                    <div className='icon-box'>
                                        <StatusBarWithIcons 
                                            progressNumber={
                                                progressNumbers[p.mc_key] 
                                                ? progressNumbers[p.mc_key][p.cst_key] 
                                                : 0
                                            } 
                                            mcData={p.mcData} />
                                        <button 
                                            className='g-button small-btn'
                                            onClick={() => navigate(`/admin/progress/${p.mc_key}_${p.cst_key}`)}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default PendingMcs
import React, { useEffect, useRef, useState } from 'react';
import './edit-data-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import flatpickr from 'flatpickr';
import { properCase } from '@/utils/properCase';

const EditDataModal = ({
    show,
    cancel,
    dataPoint,
    users,
    mcList
}) => {

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ user, setUser ] = useState(null);
    const [ mcData, setMcData ] = useState(null);
    const [ activityTypes ] = useState([
        'progress-purchased',
        'progress-started',
        'materials-viewed',
        'precheck-submitted',
        'precheck-declined',
        'precheck-approved',
        'portfolio-submitted',
        'portfolio-declined',
        'portfolio-approved',
        'badge-earned'
    ]);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const dataRef = useRef();
    const fp1Ref = useRef();
    const approveRef = useRef();

    useEffect(() => {
        if (!user || !mcData) return;
        if (fp1Ref.current && fp1Ref.current.selectedDates) {
            console.log(fp1Ref.current.selectedDates);
        }
        if (!formData.ts && fp1Ref.current.selectedDates.length > 0) {
            fp1Ref.current.destroy();
            fp1Ref.current = null;
            return;
        }
        const timeElement = document.getElementById('timestamp');
        if (!fp1Ref.current) {
            const flat1 = flatpickr(timeElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: true,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        saveData('ts', date)
                    }
                }
            );
            fp1Ref.current = flat1;
            // console.log('set fp')
        }
        if (formData.ts && fp1Ref.current) {
            fp1Ref.current.setDate(new Date(formData.ts));
        }
    }, [user, mcData, formData])

    useEffect(() => {
        if (!mcList || !users) return;
        if (!dataPoint) {
            dataRef.current = {};
            setFormData(dataRef.current);
            return;
        }
        dataRef.current = {...dataPoint};
        setFormData(dataRef.current);
        setUser(users[dataPoint.cst_key]);
        setMcData(mcList[dataPoint.mc_key]);
    }, [dataPoint, mcList, users])

    function saveData(key, value) {
        dataRef.current = {
            ...dataRef.current,
            ...{[key]: value}
        }
        setFormData(dataRef.current);
    }

    async function saveDataPointChanges() {
        let tempData = {...dataRef.current};
        console.log(tempData);
    }

    function deleteDataPoint() {
        setConfirmMessage('Are you sure you want to delete this data point? This action cannot be undone.');
        approveRef.current = () => {
            continueDelete();
        }

        async function continueDelete() {
            let tempData = {...dataRef.current};
            console.log(tempData);
        }
    }

    return (
        <div className='edit-data-modal'>
            <Modal
                show={show}
                cancel={cancel}
                closeButton={true}
                text='Edit Data Point' >
                <div className='edit-data-modal-content'>
                    {
                        (user && mcData && dataPoint) &&
                        <div className='edit-data-form'>
                            <div className='field disabled'>
                                <label>User</label>
                                <input 
                                    value={`${user.fName} ${user.lName}`}
                                    onChange={(e) => saveData('cst_key', e.target.value)} />
                            </div>
                            <div className='field disabled'>
                                <label>Micro-Credential</label>
                                <input
                                    value={mcData.name}
                                    onChange={e => saveData('mc_key', e.target.value)} />
                            </div>
                            <div className='field'>
                                <label>Activity Type</label>
                                <select
                                    value={formData.type ?? ''}
                                    onChange={(e) => saveData('type', e.target.value)}>
                                    <option value=''>Choose a type...</option>
                                    {
                                        (activityTypes) &&
                                        activityTypes.map(t => (
                                            <option key={t} value={t}>{properCase(t.replace('-', ' '))}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='field'>
                                <label>Date</label>
                                <input className="flatpickr flatpickr-input" type="text" id="timestamp" />
                            </div>
                        </div>
                    }
                    <div className='meta'>{dataPoint && dataPoint.id}</div>
                    <hr />
                    <div className='buttons with-left-button'>
                        {
                            (loading)
                            ?
                            <>
                                <div>
                                    <button className='g-button small-btn red'>Delete</button>
                                </div>
                                <button className='g-button'>Cancel</button>
                                <button className='g-button'><ThreeDotsLoader /></button>
                            </>
                            :
                            <>
                                <div>
                                    <button 
                                        className='g-button small-btn red'
                                        onClick={() => deleteDataPoint()}>
                                        Delete Data Point
                                    </button>
                                </div>
                                <button
                                    className='g-button'
                                    onClick={() => cancel()}>
                                    Cancel
                                </button>
                                <button 
                                    className='g-button primary'
                                    onClick={() => saveDataPointChanges()} >
                                    Save Changes
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
        </div>
    )
}

export default EditDataModal
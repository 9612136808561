import { convertRemToPixels } from '@/utils/remToPixels';
import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

const LineChart = ({ recentActivity, chartHeight }) => {

    const [ boxHeight, setBoxHeight ] = useState(0);

    useEffect(() => {
        const box = document.querySelector('.graph-box');
        if (box) {
            const height = box.offsetHeight;
            const remHeight = convertRemToPixels(2);
            const chartHeight = height - remHeight - 42
            setBoxHeight(chartHeight);
        } else if (chartHeight) {
            setBoxHeight(chartHeight);
        }
    }, [])
  
    const [ data, setData ] = useState({
        series: [{
            name: "Activity",
            data: [0,0,0,0,0,0,0,0,0,0]
        }],
        options: {}
    });

    useEffect(() => {
        // console.log(recentActivity)
        const lastTenDays = getLastTenDaysOfWeek();
        const hoursPerDay = getHoursPerDay();
        const dataObj = {
            series: [{
                // name: "Actions",
                data: hoursPerDay,
            }],
            options: {
                chart: {
                    height: 550,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                        }
                    }
                    // sparkline: {
                    //     enabled: true
                    // }
                },
                colors: ['var(--primary'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight',
                    width: 2,
                },
                // title: {
                //     text: 'Hours per day',
                //     align: 'left'
                // },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: lastTenDays,
                },
                yaxis: {
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (value) => {
                            return value.toFixed(0)
                        },
                    }
                },
                
            },
        };
        setData(dataObj);
    }, [recentActivity]);

    function getLastTenDaysOfWeek() {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const today = new Date(); // Get the current date and time
        const lastTenDays = [];
      
        for (let i = 9; i >= 0; i--) {
            const previousDay = new Date(today);
            previousDay.setDate(today.getDate() - i);
            lastTenDays.push({ day: daysOfWeek[previousDay.getDay()], date: previousDay.toDateString() });
        }
      
        return lastTenDays.map(d => d.day);
    }
      
    function getHoursPerDay() {
        if (!recentActivity) {return;}
        const currentDate = new Date();
        const tenDaysAgo = new Date(currentDate);
        tenDaysAgo.setDate(currentDate.getDate() - 9);
        tenDaysAgo.setHours(0, 0, 0, 0);
        let countObj = {
            '0': 0,
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
            '9': 0,
        }
        const tenDays = tenDaysAgo.getTime();
        const nineDays = tenDays + 86400000;
        const eightDays = nineDays + 86400000;
        const sevenDays = eightDays + 86400000;
        const sixDays = sevenDays + 86400000;
        const fiveDays = sixDays + 86400000;
        const fourDays = fiveDays + 86400000;
        const threeDays = fourDays + 86400000;
        const twoDays = threeDays + 86400000;
        const oneDay = twoDays + 86400000;
        for (let obj of Object.values(recentActivity)) {
            const d = obj.ts;
            // console.log(d)
            if (!d) {continue;}
            if (d > tenDays && d < nineDays) {
                countObj['0'] += 1;
            } else if (d > nineDays && d < eightDays) {
                countObj['1'] += 1;
            } else if (d > eightDays && d < sevenDays) {
                countObj['2'] += 1;
            } else if (d > sevenDays && d < sixDays) {
                countObj['3'] += 1;
            } else if (d > sixDays && d < fiveDays) {
                countObj['4'] += 1;
            } else if (d > fiveDays && d < fourDays) {
                countObj['5'] += 1;
            } else if (d > fourDays && d < threeDays) {
                countObj['6'] += 1;
            } else if (d > threeDays && d < twoDays) {
                countObj['7'] += 1;
            } else if (d > twoDays && d < oneDay) {
                countObj['8'] += 1;
            } else if (d > oneDay && d < new Date().getTime()) {
                countObj['9'] += 1;
            }
        }
        const arr = Object.values(countObj).map(o => o)
        // console.log(arr)
        return arr;
    }
  
    return (
        <div>
            {
                (data) &&
                    <Chart
                    options={data.options}
                    series={data.series}
                    type="line"
                    height={`${boxHeight}px`}
                    />
            }
        </div>
    )
}

export default LineChart
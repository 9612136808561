import React from 'react';
import './admin-functions.styles.scss';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { RandomString } from '@/utils/randomString';
import { getCollectionFromDb } from '@/utils/getDataFromDb';

const AdminFunctions = ({
    users,
    mcList
}) => {

    async function runFunction(fn) {
        let obj = {}
        if (fn === 'sendEmailWithSendGrid') {
            obj = {
                'email': 'adam.geisen@ilprincipals.org',
                'bcc': [
                    {email:'adam@tamingthetech.org'},
                    {email: 'geisenaj@gmail.com'}
                ],
                'subject': 'This is a test email',
                'title': 'Here\'s a test email for ELN Micro-Credentials',
                'body': `<p>Dear Testy,</p>
                    <p>This is a test email with html content. Here is a list: </p>
                    <ol>
                    <li>
                    Here's an item.
                    </li>
                    <li>
                    Here's another item.
                    </li>
                    </ol>
                    <p>And the final paragraph</p>`,
                'buttonLink': 'https://mcv2.edleadersnetwork.org',
                'buttonText': 'Go to the Micro-Credential Portal'
            }
            const res = await UseCloudFunction(fn, {'emailData': obj});
            console.log(res);
        } else if (fn === 'getV1Stats') {
            const v1 = await UseCloudFunction(fn, {});
            console.log(v1);
            await getCollectionFromDb({
                'path': 'stats',
                'once': true,
                'callback': callback
            })
            function callback(data) {
                let newObj = {}
                for (let d of Object.values(data)) {
                    if (d.sortCriteria) {
                        delete d.sortCriteria;
                    }
                    d.type = d.type.toLowerCase().replace(' ', '-');
                    d.id = `${d.ts}-${d.cst_key.substring(0,8)}-${RandomString(8)}`;
                    d.progressId = `${d.mc_key}_${d.cst_key}`;
                    newObj[d.id] = d;
                }
                compileStats(v1.data, newObj)
            }
            
        }
        else {
            const res = await UseCloudFunction(fn, {});
            console.log(res);
        }
        
    }

    async function compileStats(v1, v2) {
        let newStats = {...v2}
        for (let st of Object.values(v1)) {
            let mc = Object.values(mcList).filter(m => m.id === st.courseId + '-mc')[0];
            if (!mc) {
                // console.log('no Mc');
                // console.log(st);
                continue;
            } else {
                st.mc_key = mc.mc_key;
                delete st.courseId;
            }
            let user = Object.values(users).filter(u => u.email === st.uEmail.trim() || u.email2 === st.uEmail.trim())[0];
            if (!user) {
                console.log('no user');
                console.log(st);
                continue;
            } else {
                st.cst_key = user.cst_key;
                delete st.uEmail;
            }
            st.progressId = `${st.mc_key}_${st.cst_key}`;
            st.type = st.type.toLowerCase();
            st.id = `${st.ts}-${st.cst_key ? st.cst_key.substring(0,8) : st.uEmail}-${RandomString(8)}`
            newStats[st.id] = {...st}
        }
        // console.log(newStats);
        let newObj = {}
        let typeObj = {};
        for (let data of Object.values(newStats).sort((a,b) => a.ts > b.ts ? 1 : -1)) {
            let tempData = {...data};

            if (data.type === 'course-assigned') {
                tempData.type = 'progress-started';
            } else if (data.type.includes('materials')) {
                tempData.type = 'materials-viewed';
            } else if (data.type === 'final-declined') {
                tempData.type = 'portfolio-declined';
            } else if (data.type === 'final-approved') {
                tempData.type = 'portfolio-approved';
            } else if (data.type === 'evidence-submitted') {
                tempData.type = 'portfolio-submitted';
            } else if (data.type === 'badge-earned') {
                continue;
            } else if (data.type === 'progress-purchased') {
                continue;
            }

            if (
                tempData.type === 'progress-started' &&
                Object.values(newObj)
                .filter(d => 
                    d.progressId === tempData.progressId && 
                    d.type === 'progress-started'
                ).length > 0
            ) {
                continue;
            }

            newObj[tempData.id] = {...tempData}
            if (typeObj[tempData.type]) {
                typeObj[tempData.type]++;
            } else {
                typeObj[tempData.type] = 1;
            }
        }
        console.log(newObj);
        console.log(typeObj);
        const total = Object.values(typeObj).reduce((arr, cur) => arr + cur, 0);
        console.log(total);
        const res = await UseCloudFunction(
            'saveCompiledData',
            {
                'data': newObj
            }
        )
        console.log(res);
    }

    return (
        <div className='admin-functions'>
            <button 
                className='g-button'
                onClick={() => runFunction('sendTestSendGridEmail')} >
                Send Test Email
            </button>
            {/* <button
                className='g-button'
                onClick={() => runFunction('createMcIdRef')} >
                Create MC Id Reference
            </button> */}
            <button
                className='g-button'
                onClick={() => runFunction('getV1Stats')} >
                Get Compiled Stats
            </button>
            {/* <button
                className='g-button'
                onClick={() => runFunction('changePrecheckIds')} >
                Change Precheck IDs
            </button> */}
        </div>
    )
}

export default AdminFunctions
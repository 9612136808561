import React, { useEffect, useRef, useState } from 'react';
import './data-filters.styles.scss';
import { properCase } from '@/utils/properCase';
import flatpickr from 'flatpickr';
import { RandomString } from '@/utils/randomString';

const DataFilters = ({
    users,
    mcList,
    filter,
    changeFilterOption,
    clear,
    setClear
}) => {

    const [ id, setId ] = useState(RandomString(10));

    useEffect(() => {
        if (clear) {
            if (fp1Ref.current.selectedDates.length > 0) fp1Ref.current.clear();
            if (fp2Ref.current.selectedDates.length > 0) fp2Ref.current.clear();
            setClear(false);
        }
    }, [clear])
    
    const [ activityTypes ] = useState([
        'progress-purchased',
        'progress-started',
        'materials-viewed',
        'precheck-submitted',
        'precheck-declined',
        'precheck-approved',
        'portfolio-submitted',
        'portfolio-declined',
        'portfolio-approved',
        'badge-received'
    ]);
    const fp1Ref = useRef();
    const fp2Ref = useRef();

    useEffect(() => {
        if (!id) return;
        const startElement = document.getElementById('startDate_' + id);
        if (!fp1Ref.current) {
            const flat1 = flatpickr(startElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        changeFilterOption('startDate', date)
                    }
                }
            );
            fp1Ref.current = flat1;
        }
        const endElement = document.getElementById('endDate_'+ id);
        if (!fp2Ref.current) {
            const flat2 = flatpickr(endElement, 
                {
                    altInput: true,
                    altFormat: "F j, Y, h:i K",
                    enableTime: false,
                    dateFormat: "Y-m-d",
                    onChange: (dates) => {
                        const date = new Date(dates[0]).getTime()
                        changeFilterOption('endDate', date)
                    }
                }
            );
            fp2Ref.current = flat2;
        }
    }, [id])

    return (
        <div className='data-filters'>
            <div className='field small'>
                <select
                    value={filter.cst_key ?? ''}
                    onChange={e => changeFilterOption('cst_key', e.target.value)}>
                    <option value=''>Choose a User...</option>
                    {
                        (users) &&
                        Object.values(users)
                        .sort((a,b) => a.lName > b.lName ? 1 : -1)
                        .map(u => (
                            <option 
                                key={u.cst_key} 
                                value={u.cst_key}>
                                {u.lName}, {u.fName}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className='field small'>
                <select 
                    value={filter.mc_key ?? ''}
                    onChange={e => changeFilterOption('mc_key', e.target.value)}>
                    <option value=''>Choose an MC...</option>
                    {
                        (mcList) &&
                        Object.values(mcList)
                        .sort((a,b) => a.name > b.name ? 1 : -1)
                        .map(m => (
                            <option
                                key={m.mc_key}
                                value={m.mc_key}>
                                {m.name}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className='field small'>
                <select
                    value={filter.type ?? ''}
                    onChange={e => changeFilterOption('type', e.target.value)}>
                    <option value=''>Choose an Action Type...</option>
                    {
                        (activityTypes) &&
                        activityTypes.map(a => (
                            <option
                                key={a}
                                value={a}>
                                {properCase(a.replace('-', ' '))}
                            </option>
                        ))
                    }
                </select>
            </div>
            {
                (id) &&
                <>
                <div className='field small'>
                    <input 
                        className="flatpickr flatpickr-input" 
                        type="text" 
                        id={`startDate_${id}`}
                        placeholder='Start Date...' />
                </div>
                <div className={`field small ${!filter.startDate && 'hide'}`}>
                    <input 
                        className="flatpickr flatpickr-input" 
                        type="text" 
                        id={`endDate_${id}`}
                        placeholder='End Date...' />
                </div>
                </>
            }
        </div>
    )
}

export default DataFilters;
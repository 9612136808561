import React from 'react'
import './admin-content.styles.scss'

import { Navigate, Route, Routes } from 'react-router-dom'
import McList from './mc-list/mc-list.component'
import EmailList from './email-list/email-list.component'
import AdminData from './data/admin-data.component'
import AdminUsers from './users/admin-users.component'
import CreateMcFormContainer from './create-edit-mc/create-mc-form-container.component'
import CreateUser from './users/create-user/create-user.component'
import ViewUserData from './users/view-user-data.component'
import ViewMcData from './mc-list/view-mc-data.component'
import SettingsContainer from './settings/settings-container.component'
import AdminFunctions from './functions/admin-functions.component'
import ProgressDisplayContainer from './progress-display/progress-display-container.component'
import AdminActivityLogs from './activity-logs/activity-logs.component'
import CohortsContainer from './cohorts/cohorts-container.component'
import PendingMcs from './pending-mcs/pending-mcs.component'

const AdminContent = ({ 
    mcList,
    regs,
    nfeRegs,
    users,
    progressNumbers,
    oldUsers,
    importedUsers,
    importedMcs,
    sortCriteria, 
    sortCriteriaLists,
    activityLogs,
    cohorts
}) => {
    return (
        <div className='admin-content'>
            <Routes>
                <Route path='' element={
                    <McList
                        mcList={mcList}
                        regs={regs}
                        nfeRegs={nfeRegs}
                        importedMcs={importedMcs} 
                    />}
                ></Route>
                <Route path='createmc' element={
                    <CreateMcFormContainer
                        users={users}
                        mcList={mcList} 
                    />
                }></Route>
                <Route path='editmc/:mcId' element={
                    <CreateMcFormContainer
                        users={users}
                        mcList={mcList} 
                    />
                }></Route>
                <Route path='viewmcdata/:mcId' element={
                    <ViewMcData 
                        mcList={mcList}
                        regs={regs}
                        nfeRegs={nfeRegs}
                        users={users}
                        progressNumbers={progressNumbers}
                    />
                }></Route>
                <Route path='convert' element={
                    <CreateMcFormContainer 
                        users={users}
                        convert={true}
                    />
                }></Route>
                <Route path='settings' element={
                    <SettingsContainer 
                        sortCriteria={sortCriteria} 
                    />
                }></Route>
                <Route path='users/*' element={
                    <AdminUsers 
                        users={users}  
                        importedUsers={importedUsers}
                        oldUsers={oldUsers}
                        nfeRegs={nfeRegs}
                    />
                }></Route>
                <Route path='importuser/:importId' element={
                    <CreateUser 
                        users={users}
                        oldUsers={oldUsers}
                    />
                }></Route>
                <Route path='edituser/:userId' element={
                    <CreateUser 
                        users={users}
                        oldUsers={oldUsers} 
                    />
                }></Route>
                <Route path='createuser' element={
                    <CreateUser 
                        users={users}
                        oldUsers={oldUsers} 
                    />
                }></Route>
                <Route path='userdata/:userId' element={
                    <ViewUserData 
                        mcList={mcList}
                        users={users}
                        regs={regs}
                        nfeRegs={nfeRegs}
                        progressNumbers={progressNumbers}
                        sortCriteria={sortCriteria}
                        sortCriteriaLists={sortCriteriaLists} />
                }></Route>
                <Route path='data' element={
                    <AdminData 
                        users={users}
                        mcList={mcList}
                        activityLogs={activityLogs}
                        nfeRegs={nfeRegs} />
                }></Route>
                <Route path='functions' element={
                    <AdminFunctions
                        users={users}
                        mcList={mcList} />
                }></Route>
                <Route path='cohorts/*' element={
                    <CohortsContainer
                        users={users}
                        mcList={mcList}
                        cohorts={cohorts} />
                }></Route>
                <Route path='progress/:progressId' element={
                    <ProgressDisplayContainer
                        users={users}
                        mcList={mcList} />
                }></Route>
                <Route path='activity' element={
                    <AdminActivityLogs
                        users={users}
                        activityLogs={activityLogs} />
                }></Route>
                <Route path='pendingmcs' element={
                    <PendingMcs
                        users={users}
                        mcList={mcList}
                        progressNumbers={progressNumbers} />
                }></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </div>
    )
}

export default AdminContent
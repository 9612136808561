import React, { useState, useEffect } from 'react'
import './modal.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { RandomString } from '@/utils/randomString';

const Modal = ({ cancel, show, children, text, closeButton, cls, cls2, backgroundClose, idCode }) => {
	
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const [ mainHeight, setMainHeight ] = useState(2000);
    const [ id, setId ] = useState(RandomString(10));

    useEffect(() => {
        if (!idCode) {
            setId(RandomString(10));
        } else {
            setId(idCode);
        }
    }, [idCode])
	
    useEffect(() => {
        if (!show) {
            setMainHeight(2000);
        };
        // setTimeout(() => {
            // const modalChildren = document.getElementById(`basic-modal-${id}`);
            // console.log(modalChildren);
            // const height = modalChildren.offsetHeight;
            // console.log(height);
            // setMainHeight(height - 100);
            // console.log(height);
        // }, 500);
    }, [show])

	useEffect(() => {
        // console.log(show)
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 

    function closeModal() {
        if (backgroundClose) {
            cancel();
        }
    }

    
  	
	return (
    	<div className={showHideClassName} onClick={() => closeModal()}>
            {
                (id) &&
                <section 
                    id={`basic-modal-${id}`} 
                    className={`modal-main ${cls ?? ''}`}
                >
                    <div className='close-button-div'>
                        {
                            (text) &&
                            <h3>{text}</h3>
                        }
                        {
                            (closeButton) &&
                            <FontAwesomeIcon 
                                className='close-button' 
                                icon={faXmark} 
                                size='lg' 
                                onClick={cancel} />
                        }
                    </div>
                    {
                        (text || closeButton) &&
                        <hr />
                    }
                    {
                        (children) &&
                        <div 
                            id={`modal-children-${id}`} 
                            className={`modal-children ${cls2 ?? ''}`}
                            // style={{'maxHeight': mainHeight + 'px'}}
                        >
                            {children}
                        </div>
                    }
                    {/* <div className="g-space-1"></div> */}
                    <div className='spinner hide'>
                        <div className="uk-overlay-default uk-position-cover"></div>
                        <div className="uk-overlay uk-position-center uk-dark">
                            <div data-uk-spinner="ratio: 3"></div>
                        </div>
                    </div>
                </section>
            }
    	</div>
  	);
}

export default Modal

// export const useAlertConfirmation = () => {
//     const [ showAlert, setShowAlert ] = useState(true);

//     Alert
// }


export const ModalConfirm = ({ show, cancel, onApprove, onDeny, message, approveButton, denyButton, hideDeny }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    function approve() {
        onApprove();
        cancel();
    }

    function deny() {
        if (onDeny) onDeny();
        cancel();
    }

    return (
        <div className={showHideClassName} >
      		
            <section className={`modal-main alert`}>
                <div className={`modal-children`}>
                    <div dangerouslySetInnerHTML={{__html: message}} ></div>
                </div>
                <div className="g-space-20"></div>
                <div className='alert-buttons'>
                    {
                        (!hideDeny) &&
                        <button className='g-button med-btn' onClick={() => deny()}>{denyButton ?? 'Cancel'}</button>
                    }
                    <button className='g-button med-btn' onClick={() => approve()}>{approveButton ?? 'OK'}</button>
                </div>
            </section>
        </div>
    )
}

export const ModalAlert = ({ show, cancel, message, onApprove }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    function clickOk() {
        cancel();
        if (onApprove) {
            onApprove();
        } 
    }

    return (
        <div className={showHideClassName} >
      		
            <section className={`modal-main alert`}>
                <div className={`modal-children`}>
                    <div dangerouslySetInnerHTML={{__html: message}} ></div>
                </div>
                <div className="g-space-20"></div>
                <div className='alert-buttons'>
                    <button className='g-button med-btn' onClick={() => clickOk()}>Ok</button>
                </div>
            </section>
        </div>
    )
}
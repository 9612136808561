import React, { useRef, useState } from 'react';
import './cohort-create-form.styles.scss';
import BackButton from '@/components/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import UploadIcon from '@/components/SHARED/upload-icon/upload-icon.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { te } from 'date-fns/locale';
import { UseCloudFunction } from '@/utils/firebase.utils';

const CohortCreateForm = ({
    cohort,
    users,
    mcList
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const dataRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();

    function saveData(key, value) {
        dataRef.current = {
            ...dataRef.current,
            ...{[key]: value}
        }
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        const reqs = ['name', 'org', 'icon']
        for (let r of reqs) {
            if (!formData[r]) {
                setAlertMessage('Please complete the form before saving this Cohort.');
                return;
            }
        }
        setLoading(true)
        if (!formData.icon) {
            saveCohort(null);
        } else if (formData.icon.upload && formData.icon.type.indexOf('gif') == -1) {
            formData.icon.croppie.result({
                type:'blob',
                size: {'width': 212, 'height': 212},
                quality: 0.7,
            })
            .then((blob) => {
                uploadImage(formData.icon, blob)
                return;
            })
        } else if (formData.icon.upload) {
            uploadImage(formData.icon, formData.icon.file)
            return;
        } else if (formData.icon.url) {
            saveCohort(formData.icon.url)
            return;
        } else if (formData.icon) {
            saveCohort(formData.icon)
        }
    }
                
    function uploadImage(profileIcon, blob) {		
        const now = new Date().getTime();
        const profileRef = ref(storage, `uploaded-cohort-icons/${now}-${formData.icon.name}`)
        const metadata = {
            contentType: formData.icon.type,
            owner: userProfile.cst_key
        };
        uploadBytes(profileRef, blob, metadata).then((snapshot) => {
            getDownloadURL(profileRef)
            .then((url) => {
                saveCohort(url)
                return;
            })
        })
    }
                
    const saveCohort = async (url) => {
        const now = new Date().getTime();
        let tempData = {...dataRef.current};
        tempData.ts = now;
        if (url) {
            tempData.icon = url;
        }
        if (!tempData.id) {
            tempData = {
                ...tempData,
                ...{
                    'id': `${now}_cohort`,
                    'ts_created': now,
                    'status': 'active'
                }
            }
        }
        const res = await UseCloudFunction(
            'saveCohort', 
            {
                'cohort': tempData, 
                'cst_key': userProfile.cst_key
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Try again later. (Error: ${res.error})`);
            return;
        }
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='cohort-create-form'>
            <BackButton cancel={() => navigate(-1)} />
            <div className='section-title'>
                {cohort ? 'Edit Cohort' : 'Create a New Cohort'}
            </div>
            <hr />
            <section className='cohort-form'>
                <div className='field small'>
                    <label>Cohort Name</label>
                    <input
                        type='text'
                        value={formData.name ?? ''}
                        onChange={e => saveData('name', e.target.value)} />
                </div>
                <div className='field small'>
                    <label>Organization</label>
                    <input
                        type='text'
                        value={formData.org ?? ''}
                        onChange={e => saveData('org', e.target.value)} />
                </div>
                <UploadIcon 
                    formData={formData}
                    saveData={saveData} />        
                <div className='g-space-2'></div>
                <div className='buttons'>
                    <button 
                        className='g-button'
                        onClick={() => navigate(-1)}>
                        Cancel
                    </button>
                    <button
                        className='g-button primary'
                        onClick={() => handleSubmit()} >
                        Save Cohort
                    </button>
                </div>
            </section>
        </div>
    )
}

export default CohortCreateForm
import React, { useEffect, useState } from 'react';
import './progress-content-item.styles.scss';
import { properCase } from '@/utils/properCase';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import PreviousAttemptsModal from '@/components/EVAL/evaluation-inputs/previous-attempts-modal.component';

const ProgressContentItem = ({
    content,
    allContent,
    progress
}) => {

    const [ progData, setProgData ] = useState(null);
    const [ progStatus, setProgStatus ] = useState('incomplete');
    const [ uploads, setUploads ] = useState({});
    const [ declinedAttempts, setDeclinedAttempts ] = useState([]);
    const [ showPreviousAttempts, setShowPreviousAttempts ] = useState(false);
    const [ selectedDetail, setSelectedDetail ] = useState(null);
    const [ lastSubmission, setLastSubmission ] = useState(null);

    useEffect(() => {
        console.log(allContent);
    }, [allContent])

    useEffect(() => {
        if (!progress || !content || !allContent) return;
        console.log(content);
        setUploads(progress.uploads ?? {})
        let obj = {
            'prog': progress[`content_${content.id}`] ?? {},
            'eval': progress[`eval_${content.id}`] ?? {}
        }
        setProgData(obj);
        // console.log(obj);
        if (obj.prog.status) {
            setProgStatus(obj.prog.status);
        }
        if (content.id === 'materials' && obj.prog.timestamp) {
            setProgStatus('completed');
        }
        if (progress.submitted) {
            for (let sub of Object.values(progress.submitted)) {
                setLastSubmission(sub);
            }
        }
        if (content.id === 'precheck') {
            let sel = allContent.filter(a => a.id === obj.prog.detailId)[0]
            setSelectedDetail(sel);
        }
        if (progress.declinedAttempts) {
            // console.log(detailId);
            let newDeclinedAttempts = Object.values(progress.declinedAttempts).filter(d => d.id === content.id);
            const uploads = {...progress.uploads};
            let newArr = [];
            for (let a of newDeclinedAttempts) {
                let newAtt = {...a};
                let newAttUploads = {...a.upload};
                for (let u in a.upload) {
                    if (!u) break;
                    const upload = uploads[newAttUploads[u]];
                    newAttUploads[u] = upload;
                }
                newAtt.upload = newAttUploads;
                newArr.push(newAtt);
            }

            setDeclinedAttempts(newArr);
        }
    }, [progress, content, allContent])

    async function goToEvidenceFile(link) {
        const url = await getUploadUrlFromPath(link);
        window.open(url, '_new');
    }

    return (
        <div className='progress-content-item g-list-item'>
            {
                (content.type === 'header') &&
                <div className='header-item'>
                    <div className='header'>
                        {content.name}:
                    
                    </div>
                    <div className='meta'>{content.desc}</div>
                </div>
            }
            {
                (content.type === 'content') &&
                <div className='content-item'>
                    <div className='content-info'>
                        <div>
                            <div className='header'>
                                {content.name}
                            </div>
                            {
                                (content.id !== 'overview' && content.id !== 'closing') &&
                                <div className='meta' dangerouslySetInnerHTML={{__html: content.desc}}></div>
                            }  
                        </div>
                        <div className={`status-box g-list-item ${progStatus}`}>
                            {properCase(progStatus)}
                        </div>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (selectedDetail) &&
                        <>
                            <div className='g-list-item'>
                                <span className='meta'>Selected Detail:</span><br />
                                <b>{selectedDetail.id}:</b> {selectedDetail.desc}
                            </div>
                            <div className="g-space-1"></div>
                        </>
                    }
                    {
                        (content.id === 'overview' || content.id === 'closing')
                        ?
                        <div>
                            <button className='g-button small-btn'>
                                View Instructions
                            </button>
                            <div className="g-space-0-5"></div>
                            {
                                (progData && progData.prog && progData.prog.timestamp && uploads && uploads[progData.prog.timestamp.toString()]) &&
                                <EvidenceButton 
                                    evidenceData={uploads[progData.prog.timestamp.toString()]} 
                                    onClick={() => goToEvidenceFile(uploads[progData.prog.timestamp.toString()].link)} />
                            }
                            <div className="g-space-0-5"></div>
                        </div>
                        :
                        (progData && progData.prog.exp) &&
                        progData.prog.exp.map((e, i) => {
                            if (!e) return;
                            const upload = uploads[progData.prog.upload[i]]
                            return (
                                <div key={i} className='exp-item' >
                                    <div className='exp-head'>
                                        {/* <b>Explanation {i+1}</b> */}
                                        {
                                            (upload) &&
                                            <EvidenceButton 
                                                evidenceData={upload} 
                                                onClick={() => goToEvidenceFile(upload.link)} />
                                        }
                                    </div>
                                    <div className="g-space-1"></div>
                                    <div>{e}</div>
                                    <hr />
                                </div>
                            )
                        })
                    }
                    {
                        <div className='meta'>
                            {
                                (content.id !== 'overview' && content.id !== 'closing') &&
                                <div style={{'margin': '-1rem'}}></div>
                            }
                            Submitted: {
                                (
                                    lastSubmission && 
                                    content.id !== 'precheck' && 
                                    (
                                        progress.content_precheck &&
                                        content.id !== progress.content_precheck.detailId
                                    )
                                )
                                ?
                                format(new Date(lastSubmission.timestamp), "Pp")
                                : 
                                (progData && progData.prog && progData.prog.timestamp)
                                ? 
                                format(new Date(progData.prog.timestamp), "Pp")
                                : null
                            }
                            <div className="g-space-1"></div>
                        </div>
                    }
                    {
                        (declinedAttempts.length > 0) &&  // && currentData.progress.pending
                        <div>
                            <div className="g-space-0-5"></div>
                            <button className='g-button small-btn prev-att-btn' onClick={() => setShowPreviousAttempts(true)}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />View Previous Attempts
                            </button>
                            <div className="g-space-1"></div>
                        </div>
                    }
                    {
                        (content.id !== 'materials') &&
                        ((progData && progData.eval.timestamp) 
                        ?
                        <div className='eval-block g-list-item'>
                            <b>Evaluation</b>
                            <div className="g-space-0-5"></div>
                            <div className='checklist'>
                                <div className='checklist-item'>
                                    <FontAwesomeIcon 
                                        icon={
                                            progData.eval.located 
                                            ? faCheckSquare 
                                            : faSquare
                                        }
                                        className={
                                            progData.eval.located
                                            ? 'green'
                                            : ''
                                        } />
                                    Provided evidence location within artifact
                                </div>
                                <div className='checklist-item'>
                                    <FontAwesomeIcon 
                                        icon={
                                            progData.eval.explained 
                                            ? faCheckSquare 
                                            : faSquare
                                        }
                                        className={
                                            progData.eval.explained
                                            ? 'green'
                                            : ''
                                        } />
                                    Explained how artifact demonstrates detail
                                </div>
                                <div className='checklist-item'>
                                    <FontAwesomeIcon 
                                        icon={
                                            progData.eval.completed 
                                            ? faCheckSquare 
                                            : faSquare
                                        }
                                        className={
                                            progData.eval.completed
                                            ? 'green'
                                            : ''
                                        } />
                                    Artifact completely demonstrates detail
                                </div>
                            </div>
                            <div className="g-space-1"></div>
                            {
                                (progData.eval.comment) &&
                                <div >
                                    <div className='meta'>{format(new Date(progData.eval.timestamp), 'Pp')}</div>
                                    <div>"{progData.eval.comment}"</div>
                                    <div className="g-space-0-5"></div>
                                </div>
                            }
                            {
                                (progData.eval.prev_comments) &&
                                Object.values(progData.eval.prev_comments).map(c => (
                                    <div key={c.timestamp}>
                                        <div className='meta'>{format(new Date(c.timestamp), 'Pp')}</div>
                                        <div>"{c.comment}"</div>
                                        <div className="g-space-0-5"></div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div className='eval-block g-list-item'>
                            <span className='meta'>No evaluation yet...</span>
                        </div>)
                    }
                </div>
            }
            <PreviousAttemptsModal 
                previousAttempts={declinedAttempts} 
                show={showPreviousAttempts} 
                cancel={() => setShowPreviousAttempts(false)} />
        </div>
    )
}

export default ProgressContentItem
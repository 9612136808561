import React, { useEffect, useState } from 'react'
import './upload-form.styles.scss';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import ArtifactListItem from '@/components/SHARED/artifact-list-item/artifact-list-item.component';

const UploadForm = ({ 
    mcData, 
    mcProgress, 
    cancel, 
    setAlertMessage, 
    chooseArtifact, 
    selectedTempArtifact,
    setSelectedTempArtifact,
    option, 
    clear,
    setClear,
    file,
    setFile
}) => {
    
    const profile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(false);
    const storage = getStorage();

    useEffect(() => {
        if (clear) {
            clearForm();
        }
    }, [clear])

    useEffect(() => {
        console.log(selectedTempArtifact);
    }, [selectedTempArtifact])

    function handleFileUpload (e) {
        function cancelUpload() {
            document.getElementById('file-upload').value = null;
            setFile(null);
            // setFileName(null);
            return;
        }
        const uploadedFile = e[0];
        const ext = uploadedFile.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        // console.log(ext);
        const allowed = ['jpg','bmp','png','gif','tif','xls','xlsx','csv','pdf','doc','docx', 'ppt', 'pptx']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        // console.log(file.size);
        if (uploadedFile.size > 30 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 30MB.');
            cancelUpload();
            return;
        }
        setFile(uploadedFile);
    };

    

    function makeEvidenceSelection(id) {
        console.log(id);
        // chooseArtifact(id);
        clearForm();
    }

    function selectEvidence(id) {
        console.log(id);
        setSelectedTempArtifact(id)
    }

    function clearForm() {
        if (document.getElementById('file-upload')) {
            document.getElementById('file-upload').value = null;
        }
        if (document.getElementById('upload-label')) {
            document.getElementById('upload-label').value = null;
        }
        setLoading(false);
        setClear(false);
        cancel();
    }

    return (
        <div className='upload-form'>
        {
            (mcData && mcProgress) &&
            (
                (option === 'choose') 
                ?
                <div>
                    <h3>Choose an Uploaded Artifact</h3>
                    <div className='g-space-20'></div>
                    {
                        (mcProgress && mcProgress.uploads)
                        ?
                        <div className='upload-list'>
                            {
                                Object.values(mcProgress.uploads).filter(u => !u.overview_closing)
                                .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                .map(u => (
                                    <ArtifactListItem 
                                        key={u.timestamp} 
                                        artifactData={u} 
                                        onClick={selectEvidence}
                                        selectedTempArtifact={selectedTempArtifact} />
                                ))
                            }
                        </div>
                        :
                        <p>No artifacts uploaded yet...</p>
                    }
                    <div className='g-space-40'></div>
                </div>
                :
                (option === 'upload')
                ?
                <div>
                    <h3>Or Upload a New Artifact</h3>
                    <div className='g-space-1'></div>
                    <p className='meta'>You can upload images, PDFs, slideshows, spreadsheets, and documents. If you are using Google files, please download them as MS Office files before uploading.</p>
                    <div className='g-space-0-5'></div>
                    <p>If you would like to use a video or audio as evidence, please upload that file to a media service like YouTube, and make it viewable by anyone. Then, take the link to that video or audio and paste the link in a document, and upload that document as evidence.</p>
                    <div className='g-space-20'></div>
                    <input id="file-upload" type="file" onChange={(e) => handleFileUpload(e.target.files)} />
                    <div className='g-space-20'></div>
                    <div className='field'>
                        <label>Give it a name to keep things organized</label>
                        <input className='short' type='text' id='upload-label' />
                    </div>
                </div>
                : 
                null
            )
        }
        </div>
    )
}

export default UploadForm
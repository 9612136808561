import React from 'react';
import './progress-header.styles.scss';
import { useSelector } from 'react-redux';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';

const ProgressHeader = ({
    user,
    mcData,
}) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    
    return (
        <div className={`progress-header ${screenSize}`}>
            {
                (mcData) &&
                <>
                <div className='user-info'>
                    <UserIcon profile={user} />
                    {user.fName} {user.lName}
                </div>
                <div className='mc-info'>
                    {mcData.name}
                    <img src={mcData.imageUrl} className='icon' />
                </div>
                </>
            }
        </div>
    )
}

export default ProgressHeader
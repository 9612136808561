import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './admin.styles.scss'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AdminLayout from '@/components/ADMIN/admin-layout/admin-layout.component'
import AdminContent from '@/components/ADMIN/admin-content.component'
import { 
    getActivityLogsFromDb, 
    getCollectionFromDb, 
    getCompiledDataFromDb, 
    getImportedMcsFromDb, 
    getImportedUsersFromDb, 
    getMcsFromDb, 
    getUniqueDocFromDb, 
    getUsersFromDb 
} from '@/utils/getDataFromDb'
import { UseCloudFunction } from '@/utils/firebase.utils'
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component'
import { off } from 'firebase/database'

const AdminPage = () => {

    const dbData = useSelector(state => state.userData.dbData);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ sortCriteria, setSortCriteria ] = useState(null);
    const [ sortCriteriaLists, setSortCriteriaLists ] = useState(null);
    const [ mcList, setMcList ] = useState(null);
    const [ regs, setRegs ] = useState(null);
    const [ nfeRegs, setNfeRegs ] = useState({});
    const [ finalMcRegs, setFinalMcRegs ] = useState({});
    const [ users, setUsers ] = useState(null);
    const [ progressNumbers, setProgressNumbers ] = useState(null); 
    const [ oldUsers, setOldUsers ] = useState(null);
    const [ importedUsers, setImportedUsers ] = useState(null);
    const [ importedMcs, setImportedMcs ] = useState(null);
    const [ activityLogs, setActivityLogs ] = useState(null);
    const [ cohorts, setCohorts ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usersRef = useRef();
    const criteriaListRef = useRef();
    const mcListRef = useRef();
    const regsRef = useRef();
    const nfeRegsRef = useRef();
    const progressRef = useRef();
    const compiledDataRef = useRef();
    const activityRef = useRef();
    const cohortRef = useRef();
    let mcListener;
    let regsListener;
    let userListener;
    let progressListener;
    let importedListener;
    let importedMcsListener;
    let criteriaListener;
    let sortCriteriaListsListener;
    let compiledDataListener;
    let activityListener;
    let cohortListener;

    useEffect(() => {
        getData();
        
        return () => {
            if (mcListener) mcListener();
            if (regsListener) regsListener();
            if (userListener) userListener();
            if (progressListener) progressListener();
            if (importedListener) importedListener();
            if (importedMcsListener) importedMcsListener();
            if (criteriaListener) criteriaListener();
            if (sortCriteriaListsListener) sortCriteriaListsListener();
            if (compiledDataListener) off(compiledDataListener);
            if (activityListener) activityListener();
            if (cohortListener) cohortListener();
        }
	}, [])

    useEffect(() => {
        if (!mcList || !nfeRegs) return;
        let obj = {};
        Object.values(mcList).map(m => obj[m.mc_key] = {});
        for (let r of Object.values(nfeRegs)) {
            let matchedMcs = Object.values(mcList)
            .filter(m => m.nfe_code === r.evt_code);
            if (matchedMcs.length > 0) {
                let mc = matchedMcs[0];
                obj[mc.mc_key][r.ind_cst_key] = r;
            }
        }
        setFinalMcRegs(obj);
    }, [mcList, nfeRegs])
    
    function getData() {
        getMcs();
        getUsers();
        getUserProgress();
        getRegsFromNFE();
        getRegs();
        getOldUsers();
        getImportedList();
        getSortCriteria();
        getSortCriteriaLists();
        // getCompiledData();
        getActivityLogs();
        // getCohorts();
    }

    function getMcs() {
        getMcsFromDb({'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            mcListRef.current = {...mcListRef.current, ...{[data.mc_key]: data}}
            setMcList(mcListRef.current);
        }
        function handleListener(unsub) {
            mcListener = unsub;
        }
    }

    function getRegs() {
        getCollectionFromDb({"path": "regs", "callback": callback, "handleListener": handleListener})
        function callback(data) {
            regsRef.current = {...regsRef.current, ...{[data.mc_key]: data}}
            setRegs(regsRef.current);
        }
        function handleListener(unsub) {
            regsListener = unsub;
        }
    }

    async function getRegsFromNFE(mc_key) {
        // console.log(mc_key);
        const res = await UseCloudFunction(
            "getMCRegistrantsFromNFE",
            {
                "mc_key": mc_key,
                "cst_key": userProfile.cst_key,
            }
        )
        setNfeRegs(res.data);
        // if (!res.data) return;
        // let obj = {
        //     "count": res.data.length,
        //     "regs": {}
        // }
        // for (let u of res.data) {
        //     obj.regs[u.ind_cst_key] = {
        //         "fName": u.ind_first_name,
        //         "lName": u.ind_last_name,
        //         "cst_key": u.ind_cst_key,
        //     }
        // } 
        // nfeRegsRef.current = {...nfeRegsRef.current, ...{[mc_key]: obj}}
        // setNfeRegs(nfeRegsRef.current);
    }

    function getUsers() {
        getUsersFromDb({'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data, id) {
            usersRef.current = {
                ...usersRef.current,
                ...{[id]: data}
            }
            setUsers(usersRef.current);
        }
        function handleListener(unsub) {
            userListener = unsub;
        }
    }

    function getUserProgress() {
        getCollectionFromDb({"path": "progressNumbers", "callback": callback, "handleListener": handleListener})
        function callback(data, docId) {
            progressRef.current = {...progressRef.current, ...{[docId]: data}};
            setProgressNumbers(progressRef.current);
        }
        function handleListener(unsub) {
            progressListener = unsub;
        }
    }

    async function getOldUsers() {
        const res = await UseCloudFunction('getDataFromOldDatabase', {'url': 'https://us-central1-ipa-microcredentials.cloudfunctions.net/app/api/get-profiles', 'cst_key': userProfile.cst_key})
        let filteredObj = {}
        for (let u of Object.keys(res)) {
            if (!res[u].ignore) {
                filteredObj[u] = res[u];
            }
        }
        setOldUsers(filteredObj);
    }

    function getImportedList() {
        getImportedUsersFromDb({'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setImportedUsers(data);
        }
        function handleListener(unsub) {
            importedListener = unsub;
        }
        getImportedMcs();
    }

    function getImportedMcs() {
        getImportedMcsFromDb({'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setImportedMcs(data);
        }
        function handleListener(unsub) {
            importedMcsListener = unsub;
        }
    }

    function getSortCriteria() {
        getUniqueDocFromDb({'path': 'settings/sortCriteria', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            // console.log(data);
            setSortCriteria(data);
        }
        function handleListener(unsub) {
            criteriaListener = unsub;
        }
    }

    function getSortCriteriaLists() {
        getCollectionFromDb({'path': 'sortCriteriaList', 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            criteriaListRef.current = {...criteriaListRef.current, ...{[data.id]: data}}
            setSortCriteriaLists(criteriaListRef.current);
        }
        function handleListener(unsub) {
            sortCriteriaListsListener = unsub;
        }
    }

    // function getCompiledData() {
    //     getCompiledDataFromDb({'callback': callback});
    //     function callback(data) {
    //         // console.log(data);
    //     }
    // }

    async function getActivityLogs() {
        const tenDaysAgo = new Date();
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
        tenDaysAgo.setHours(0, 1, 0, 0); // Set to 12:01 AM
        getActivityLogsFromDb({
            'startDate': tenDaysAgo.getTime(),
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            // console.log(data);
            activityRef.current = {...activityRef.current, ...{[data.ts]: data}}
            setActivityLogs(activityRef.current);
        }
        function handleListener(unsubscribe) {
            activityListener = unsubscribe;
        }
    }

    async function getCohorts() {
        getCollectionFromDb({
            'path': 'cohorts',
            'status': 'active',
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            cohortRef.current = {
                ...cohortRef.current,
                ...{[data.id]: data}
            }
            setCohorts(cohortRef.current);
        }
    }


    return (
        <AdminLayout>
            <div className='admin-page'>
                {
                    (
                        mcList &&
                        users
                    ) 
                    ?
                    <AdminContent 
                        mcList={mcList}
                        regs={regs}
                        nfeRegs={finalMcRegs}
                        users={users}
                        progressNumbers={progressNumbers}
                        oldUsers={oldUsers}
                        importedUsers={importedUsers}
                        importedMcs={importedMcs}
                        sortCriteria={sortCriteria} 
                        sortCriteriaLists={sortCriteriaLists} 
                        activityLogs={activityLogs}
                        cohorts={cohorts}
                    />
                    :
                    <div>
                        <div className='g-space-2'></div>
                        <div className='center-col'>
                            
                            <ThreeDotsLoader />
                        </div>
                    </div>
                }
            </div>
        </AdminLayout>
    )
}

export default AdminPage
import React, { useEffect, useState } from 'react'
import './eval-detail.styles.scss';
import BackButton from '@/components/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import EvaluationInputs from '../evaluation-inputs/evaluation-inputs.component';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import EvalReflectionBox from '../eval-reflection-box/eval-reflection-box.component';
import PreviousAttemptsModal from '../evaluation-inputs/previous-attempts-modal.component';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';
import { format } from 'date-fns';
import { properCase } from '@/utils/properCase';

const EvalDetail = ({ 
    currentData, 
    allProgress, 
    content, 
    activeId, 
    saveDirtyStatus 
}) => {

    const [ detailData, setDetailData ] = useState(null);
    const [ allDetails, setAllDetails ] = useState(null);
    const [ progress, setProgress ] = useState(null);
    const [ readyToEvaluate, setReadyToEvaluate ] = useState(false);
    const [ declinedAttempts, setDeclinedAttempts ] = useState([]);
    const [ showPreviousAttempts, setShowPreviousAttempts ] = useState(false);
    const [ openingClosingContent, setOpeningClosingContent ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ submissionId, setSubmissionId ] = useState(null);
    const [ submissionDate, setSubmissionDate ] = useState(null);
    const { detailId } = useParams(); 
    const [ upload, setUpload ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!activeId || !currentData || !content || !detailId || !allProgress) return;
        let detailList = {}
        // console.log(currentData);
        if (progress && detailId !== progress.id) {
            setProgress(null);
        }
        for (let eo of content) {
            if (eo.comp) {
                for (let d of Object.values(eo.comp)) {
                    detailList[d.id] = d
                }
            } else {
                detailList[eo.id] = eo
            }
        }
        if (!detailList[detailId]) {
            navigate('/404');
            return;
        }

        if (detailId === 'precheck') {
            setProgress(allProgress[`content_${detailId}`]);
            if (allProgress.pending) {
                setReadyToEvaluate(true);
            } else {
                setReadyToEvaluate(false);
            }
            setLoading(false);
        }

        if (detailId === 'overview' || detailId === 'closing') {
            setProgress(allProgress[`content_${detailId}`]);
            setOpeningClosingContent(currentData.mcData[detailId]);
            setLoading(false);
        } else {
            setOpeningClosingContent(null);
        }
        setAllDetails(detailList);
        setDetailData(detailList[detailId]);
        if (allProgress[`content_${detailId}`] && allProgress[`content_${detailId}`].status !== 'pending' && allProgress[`content_${detailId}`].status !== 'saved') {
            setReadyToEvaluate(true);
            setProgress(allProgress[`content_${detailId}`]);
            setLoading(false);
            // return;
        }

        if (detailId === 'precheck' && allProgress.content_precheck && allProgress.content_precheck.status === 'pending') {
            setReadyToEvaluate(true);
            setProgress(allProgress.content_precheck);
            setLoading(false);
            // return;
        } 
        // if (allProgress[`content_${detailId}`] && allProgress[`content_${detailId}`].approved) {
        //     setProgress(allProgress[`content_${detailId}`]);
        //     setReadyToEvaluate(true);
            
        // } 

        if (
            detailId === 'precheck' || 
            (
                allProgress.content_precheck && 
                detailId === allProgress.content_precheck.detailId
            )
        ) {
            setSubmissionDate(format(new Date(allProgress[`content_${detailId}`].timestamp), "Pp"))
        }
        let lastSub;
        if (allProgress.submitted) {
            const subArr = Object.values(allProgress.submitted)
            .sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)
            lastSub = subArr[0];
            // console.log(lastSub)
            if (
                detailId !== 'precheck' && 
                (
                    !allProgress.content_precheck || 
                    detailId !== allProgress.content_precheck.detailId
                )
            ) {
                setSubmissionDate(format(new Date(lastSub.timestamp), "Pp"));
            }
            if (!lastSub.retTime) {
                // console.log({...allProgress[`content_${detailId}`]});
                setProgress({...allProgress[`content_${detailId}`]});
                setReadyToEvaluate(true);
                setSubmissionId(lastSub.timestamp + '_submission');
            } 
        }
        setLoading(false);
        // console.log(allProgress);
        if (allProgress.declinedAttempts) {

            // console.log(detailId);
            let newDeclinedAttempts = Object.values(allProgress.declinedAttempts).filter(d => d.id === detailId);
            const uploads = {...allProgress.uploads};
            let newArr = [];
            for (let a of newDeclinedAttempts) {
                let newAtt = {...a};
                let newAttUploads = {...a.upload};
                for (let u in a.upload) {
                    if (!u) break;
                    const upload = uploads[newAttUploads[u]];
                    newAttUploads[u] = upload;
                }
                newAtt.upload = newAttUploads;
                newArr.push(newAtt);
            }

            setDeclinedAttempts(newArr);
            // console.log(newArr);
        }
    }, [activeId, currentData, content, detailId, allProgress])

    useEffect(() => {
        if (!progress || detailId !== progress.id) return;
        // console.log(progress);
        if (detailId === 'overview' || detailId === 'closing') {
            setUpload({
                'link': progress.file,
                'name': progress.name,
                'type': progress.type,
            })
        } else {
            setUpload(null);
        }
    }, [progress])

    async function goToEvidenceFile(link) {
        const url = await getUploadUrlFromPath(link);
        window.open(url, '_new');
    }

    return (
        <div className='eval-detail-container'>
            <BackButton cancel={() => navigate(-1)} />
            <div className="g-space-1"></div>
            <div className='head-title'>{(detailData) ? <><b>{detailData.name}:</b> {detailData.desc}</> : ''}</div>
            {
                (progress && progress[`eval_${detailId}`] && progress[`eval_${detailId}`].approved) &&
                <div className='buttons'>
                    <div className='g-list-item approved'>
                        APPROVED!
                    </div>
                </div>
            }
            <hr />
            {
                (openingClosingContent) &&
                <div className=''>
                    <div dangerouslySetInnerHTML={{__html: openingClosingContent}} />
                    {
                        (readyToEvaluate && upload) &&
                        <>
                            <hr />
                            <h4>Uploaded {detailId === 'overview' ? 'Narrative' : 'Reflection'}</h4>
                            <EvidenceButton evidenceData={upload} onClick={() => goToEvidenceFile(upload.link)} />
                        </>
                    }
                    <div className='g-space-1-5'></div>
                </div>
            }
            {
                (detailId === 'precheck' && allDetails && progress) &&
                <div>
                    <div className='g-list-item'>
                        <div>Chosen Essential Outcome Detail:</div>
                        <div className='g-space-0-5'></div>
                        <div><b>{progress.detailId}</b>: {allDetails[progress.detailId] && allDetails[progress.detailId].desc}</div> 
                    </div>
                    <div className="g-space-1"></div>
                </div>
            }
            {
                (readyToEvaluate && progress && saveDirtyStatus) 
                ?
                <div className='eval-detail-content'>
                    <div className='card-head'>
                        Participant's Reflection(s) and Uploaded Artifact(s):
                    </div>
                    {
                        (progress.exp) &&
                        progress.exp.filter(e => e)
                        .map((e, index) => {
                            const upload = allProgress.uploads[progress.upload[index]];
                            return (
                                <div key={e}>
                                    <EvalReflectionBox 
                                        data={
                                            {'exp': e, 'upload': upload}
                                        }
                                        onClick={() => goToEvidenceFile(upload.link)}
                                        attempts={
                                            (
                                                declinedAttempts &&
                                                progress.status !== 'declined'
                                            ) 
                                            ? declinedAttempts.length 
                                            : 0
                                        }
                                    />
                                </div>
                            )
                        })
                        
                    }
                    {
                        (progress && submissionDate && progress.status !== 'declined') &&
                        <div className='meta'>
                            {
                                progress.status === 'saved' 
                                ? 'Saved'
                                : 'Submitted'
                            }: {submissionDate}
                        </div>
                    }
                    {
                        (declinedAttempts.length > 0) &&  // && currentData.progress.pending
                        <>
                            <div></div>
                            <div>
                                <button className='g-button small-btn prev-att-btn' onClick={() => setShowPreviousAttempts(true)}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />View Previous Attempts
                                </button>
                            </div>
                            <div ></div>
                            
                        </>    
                    }
                    <EvaluationInputs allProgress={allProgress} currentData={currentData} id={detailId} saveDirtyStatus={saveDirtyStatus} />
                </div>
                :
                <h4>No submitted content yet...</h4>
            }
            
            <PreviousAttemptsModal previousAttempts={declinedAttempts} show={showPreviousAttempts} cancel={() => setShowPreviousAttempts(false)} />
            
            <FullPageLoader show={loading} />
        </div>
    )
}

export default EvalDetail
import React, { useEffect, useState } from 'react';
import './artifact-list-item.styles.scss';
import { format } from 'date-fns';
import { findIcon } from '@/utils/findIcon';

const ArtifactListItem = ({
    artifactData,
    onClick,
    selectedTempArtifact
}) => {

    const [ evType, setEvType ] = useState(null);

    useEffect(() => {
        if (!artifactData) return;
        if (artifactData.type.indexOf('image') > -1) {
            setEvType('Image');
        } else if (artifactData.type.indexOf('pdf') > -1) {
            setEvType('PDF');
        } else if (artifactData.type.indexOf('audio') > -1) {
            setEvType('Audio');
        } else if (artifactData.type.indexOf('video') > -1) {
            setEvType('Video');
        } else if (artifactData.type.indexOf('document') > -1) {
            setEvType('Document');
        } else if (artifactData.type.indexOf('csv') > -1) {
            setEvType('Spreadsheet');
        } else {
            setEvType('File');
        }
    }, [artifactData])

    useEffect(() => {
        console.log(selectedTempArtifact);
        console.log(artifactData.id, artifactData.timestamp);
    }, [selectedTempArtifact])

    return (
        <button 
            key={artifactData.link} 
            className={`evidence-list-item g-button text-only ${
                selectedTempArtifact && 
                (
                    selectedTempArtifact === artifactData.id || 
                    selectedTempArtifact === artifactData.timestamp.toString() 
                )
                ? 'active' 
                : ''
            }`} 
            onClick={() => onClick(artifactData.id ?? artifactData.timestamp.toString())}>
            <div className='icon'>
                {findIcon(artifactData.type)}
            </div>
            <div>
                {artifactData.name}
            </div>
            <div className='meta'>
                {evType}
            </div>
            <div className='meta'>
                {format(new Date(artifactData.timestamp), "P")}
            </div>
        </button>
    )
}

export default ArtifactListItem